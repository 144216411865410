<template>
  <div class="page-wrap">
    <div class="page-head">
      <div class="breadcrumbs">
        <div class="h1">
          <router-link class="link-not-like" to="/settings">{{ $vuetify.lang.t('$vuetify.menu.settings') }}
          </router-link>
        </div>
        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.27539 13.7068L7.48229 7.49989L1.27539 1.29299" stroke="#2C1521" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="h1">
          {{ $vuetify.lang.t('$vuetify.settings.' + this.title) }}
        </div>
      </div>
    </div>
    <div class="page-head">
      <button class="btn-secondary btn" @click="
      action = 'add';
      create_open()">
        + {{ $vuetify.lang.t('$vuetify.add') }}
      </button>
    </div>

    <div class="settings-items__list">
      <div class="settings-items__item" v-for="position in items" :key="position.id">
        <div v-if="item === 'terms'">{{ position.days }}</div>
        <div v-else-if="item === 'tax'">{{ position.tax }}</div>
        <div v-else-if="item === 'banks'">{{ position.name }}</div>
        <div v-else>
          {{
            position.name[$vuetify.lang.current] ? position.name[$vuetify.lang.current] : position.name['eng'] ? position.name['eng'] : position.name['fin']
          }}
        </div>

        <div class="settings-items__buttons">
          <div v-if="!['banks', 'terms', 'tax'].includes(item)"
               class="settings-items__icon cp"
               color="grey lighten-1"
               @click="newItem = position; create_modal=true">
            <edit-icon/>
          </div>
          <div class="settings-items__icon cp" color="grey lighten-1"
               @click="currentItem = position.id; confirm_modal = true; alertError=false">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L23 23M1 23L12 12L23 1" stroke="#6A9DDA" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="create_modal">
      <div class="modal">
        <div class="h1">
          <span v-if="action == 'add'">{{ $vuetify.lang.t('$vuetify.add') }}</span><span
            v-if="action == 'edit'">{{ $vuetify.lang.t('$vuetify.edit') }}</span>
        </div>
        <v-form ref="form" @submit.prevent="">
          <v-text-field v-if="item === 'terms' || item === 'tax'" :label="$vuetify.lang.t('$vuetify.value')"
                        v-model="newItem.name" outlined :rules="[$rules.required]" type="number"></v-text-field>
          <v-text-field v-else-if="item == 'banks'" :label="$vuetify.lang.t('$vuetify.name2')" v-model="newItem.name"
                        outlined :rules="[$rules.required]"></v-text-field>
          <template v-else>
            <template v-for="lang in Object.keys($vuetify.lang.locales)">
              <v-text-field v-if="['eng', 'rus'].includes(lang)" :key=lang
                            v-model="newItem.name[lang]"
                            :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined
                            :rules="[$rules.required]"></v-text-field>
              <v-text-field v-else-if="!['en', 'ru'].includes(lang)" :key=lang v-model="newItem.name[lang]"
                            :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined></v-text-field>
            </template>
          </template>
          <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
          <div class="sign-up__buttons btn-group">
            <div class="btn btn-secondary" @click="create_close">
              {{ $vuetify.lang.t('$vuetify.cancel') }}
            </div>
            <button class="btn btn-primary" @click="addItem">
              {{ $vuetify.lang.t('$vuetify.save') }}
            </button>
          </div>
        </v-form>
      </div>
    </v-dialog>

    <ConfirmModal v-model="confirm_modal" @confirm="deleteItem">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
  </div>
</template>

<script>
import ConfirmModal from "@/components/confirmModal";
import EditIcon from "@/components/icons/EditIcon";

export default {
  name: "SettingsView",
  components: {EditIcon, ConfirmModal},
  props: {
    item: String,
  },

  data() {
    return {
      url: "",
      title: "",
      action: "",

      items: [],
      newItem: {
        name: {},
      },
      currentItem: 0,
      create_modal: false,
      confirm_modal: false,

      alertError: false,
      alertMsg: '',
    }
  },

  created() {
    if (this.$check_permissions()) {
      let title = this.item.split('-')
      if (title.length > 1)
        this.title = title[0] + title[1][0].toUpperCase() + title[1].slice(1)
      else
        this.title = this.item
      switch (this.item) {
        case 'worker-position':
          this.url = "time-tracking/profiles/positions"
          break
        case 'skills':
          this.url = "time-tracking/profiles/skills"
          break
        case 'cards':
          this.url = "time-tracking/profiles/cards"
          break
        case 'contact-position':
          this.url = "time-tracking/clients-employees/positions"
          break
        case 'cheque-categories':
          this.url = "time-tracking/cheque/categories"
          break
        case 'reports-categories':
          this.url = "time-tracking/accounting/reports-categories"
          break
        case 'goals':
          this.url = "time-tracking/waybill/goal"
          break
        case 'banks':
          this.url = "time-tracking/accounting/bank"
          this.newItem.name = ""
          break
        case 'terms':
          this.url = "time-tracking/accounting/terms"
          this.newItem.name = ""
          break
        case 'tax':
          this.url = "time-tracking/accounting/taxes"
          this.newItem.name = ""
          break
        case 'absence-reasons':
          this.url = "time-tracking/absence/reasons"
          this.newItem.name = {}
          break
        default:
          this.$router.push({"name": "404"})
      }
      this.loadData()
    }
  },

  methods: {
    loadData() {
      this.$axios({
        url: this.$hostname + this.url,
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.items = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },

    addItem() {
      if (this.$refs.form.validate()) {
        if (this.newItem.id) {
          this.editItem()
        } else {
          this.$axios({
            url: this.$hostname + this.url,
            method: "POST",
            headers: {
              Authorization:
                  "Token " +
                  (localStorage.getItem("auth_token") ||
                      sessionStorage.getItem("auth_token")),
              "Content-Type": "multipart/form-data",
            },
            data: {name: !['banks', 'terms', 'tax'].includes(this.item) ? JSON.stringify(this.newItem.name) : this.newItem.name},
          }).then(() => {
            this.loadData()
            this.create_close()
          }).catch((response) => {
            this.alertMsg = this.$catch(response)
            this.alertError = true
          })
        }
      }
    },
    editItem() {
      this.$axios({
        url: this.$hostname + this.url,
        method: "PUT",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.newItem.id,
          name: !['banks', 'terms', 'tax'].includes(this.item) ? JSON.stringify(this.newItem.name) : this.newItem.name
        },
      }).then(() => {
        this.loadData()
        this.create_close()
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },


    deleteItem() {
      this.$axios({
        url: this.$hostname + this.url,
        method: "DELETE",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {id: this.currentItem},
      }).then(() => {
        this.confirm_modal = false
        this.loadData()
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },

    create_open() {
      this.create_modal = true;
      this.alertError = false

      if (this.action == "add") {
        if (!['banks', 'terms', 'tax'].includes(this.item)) {
          this.newItem = {
            name: {}
          }
        } else
          this.newItem = {
            name: ""
          }
      }
    },

    create_close() {
      this.create_modal = false;
    },
  },
}
</script>

<style scoped>

</style>