// let url = "http://" + window.location.hostname + ":8000/";
// let url = "http://" + window.location.hostname + "/";
// let url = "http://localhost:8000/";
let url = "http://65.21.185.61/";
let media_url = url + 'media/'
let tech = false

export default {
    url: url,
    media_url: media_url,
    tech: tech
}