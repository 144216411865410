<template>
  <div class="news-item">
    <router-link class="news-item__back h1 link-not-like" to="/news">
      <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.72461 13.7069L1.51771 7.50001L7.72461 1.29312" stroke="#2C1521" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      {{ $vuetify.lang.t('$vuetify.news') }}
    </router-link>

    <div class="news-item__grid">
      <div class="cards__single">
        <div class="news-item__preview">
          <img :src="$media_url + currentNew.photo_path" alt=""/>
        </div>

        <div class="news-item__content">
          <h2>{{ currentNew.title }}</h2>
          <div v-html="currentNew.text"></div>
        </div>

        <div class="news-item__footer">
          <div v-if="$parent.admin" class="news-item__buttons btn-action__group">
            <router-link class="btn-icon btn-action" :to="'/newsEdit/' + id">
              <picture>
                <edit-icon/>
              </picture>
            </router-link>
            <button class="btn-icon btn-action" @click="confirm_modal = true;">
              <picture>
                <delete-icon/>
              </picture>
            </button>
            <button class="btn-icon btn-action" @click="confirm_archive_modal = true;">
              <picture>
                <archive-icon/>
              </picture>
            </button>
          </div>

          <div class="news-item__info">
            <span> {{ currentNew.date }} </span>
            <svg width="2" height="2" viewBox="0 0 2 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="1" cy="1" r="1" fill="#ADB2B7"/>
            </svg>
            <!--<span> Алексей Горбатовский </span>-->
          </div>
        </div>
      </div>
      <div class="news-item__others">
        <div class="h2">
          {{ $vuetify.lang.t('$vuetify.readAlso') }}

        </div>
        <div class="news-item__list">
          <news-card v-for="item in otherNews" :key="item.id"
                     :id="item.id"
                     :image="$media_url + item.photo_path"
                     :title="item.title"
                     :author="item.author ? (item.author.name + ' ' + item.author.lastname) : ''"
                     :date="item.create_date"
          ></news-card>
        </div>
      </div>
    </div>
    <ConfirmModal v-model="confirm_modal" @confirm="deleteNew">
      <span v-if="alertError">{{ alertMsg }}</span></ConfirmModal>
    <ConfirmArchiveModal v-model="confirm_archive_modal" @confirm="archiveNew" :active="this.currentNew.active">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmArchiveModal>
  </div>
</template>

<script>
import ConfirmModal from "@/components/confirmModal.vue";
import EditIcon from "@/components/icons/EditIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import NewsCard from "@/views/parts/NewsCard";
import ConfirmArchiveModal from "@/components/ConfirmArchiveModal";
import ArchiveIcon from "@/components/icons/ArchiveIcon";


export default {
  name: "news-item",
  components: {
    ArchiveIcon,
    ConfirmArchiveModal,
    NewsCard,
    DeleteIcon,
    EditIcon,
    ConfirmModal
  },
  props: {
    id: String,
  },

  mounted() {
    this.loadData();
    this.loadOtherNews()
  },

  data: function () {
    return {
      currentNew: {},
      confirm_modal: false,
      confirm_archive_modal: false,

      otherNews: [],
    };
  },

  methods: {
    loadData() {
      this.$axios({
        url: this.$hostname + "time-tracking/news/" + this.id,
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.currentNew = response.data.data.data[0];
        console.log(this.currentNew);
      });
    },
    loadOtherNews() {
      this.$axios({
        url: this.$hostname + "time-tracking/news",
        method: "GET",
        params: {
          except: this.id,
          limit: 3
        }
      }).then((response) => {
        this.otherNews = response.data.data.data
      });
    },

    archiveNew() {
      this.$axios({
        url: this.$hostname + "time-tracking/news",
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.currentNew.id,
          title: this.currentNew.title,
          text: this.currentNew.text,
          active: !this.currentNew.active
        },
      }).then(() => {
        this.confirm_archive_modal = false
      }).catch((errors) => {
        this.alertError = true
        this.alertMsg = this.$catch(errors)
      });
    },
    deleteNew() {
      this.$axios({
        url: this.$hostname + "time-tracking/news",
        method: "DELETE",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.id,
        },
      }).then((response) => {
        console.log(response)
        this.$router.push('/news')
      })
    }
  },
};
</script>
