<template>
  <div class="page-wrap">
    <div class="page-head">
      <div class="breadcrumbs">
        <div class="h1">
          <router-link class="link-not-like" to="/settings">{{ $vuetify.lang.t('$vuetify.menu.settings') }}
          </router-link>
        </div>
        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.27539 13.7068L7.48229 7.49989L1.27539 1.29299" stroke="#2C1521" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="h1">
          {{ $vuetify.lang.t('$vuetify.settings.groups') }}
        </div>
      </div>
    </div>
    <div class="page-head">
      <button class="btn-secondary btn" @click="
      action = 'add';
      group_create_open()">
        + {{ $vuetify.lang.t('$vuetify.addGroup') }}
      </button>
    </div>

    <v-list class="groups__list">
      <v-list-group class="cards__single" v-for="group in groups" :key="group.id" v-model="group.active" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="groups__name">
              {{
                group.name[$vuetify.lang.current] ? group.name[$vuetify.lang.current] : group.name['eng'] ? group.name['eng'] : group.name['fin']
              }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-for="func in functions[JSON.stringify(group.name)]">
          <div class="groups-single__functions" :key="func.text">
            <!--            :disabled="group.name['eng'] == 'Admin'"-->
            <div class="label"> {{ $vuetify.lang.t('$vuetify.menu.' + func.name.toLowerCase()) }}</div>
            <v-checkbox v-model="func.data.read"
                        :label="$vuetify.lang.t('$vuetify.settings.read')"
                        @change="putFunction(func.data)"></v-checkbox>
            <v-checkbox v-model="func.data.edit"
                        :label="$vuetify.lang.t('$vuetify.settings.edit')"
                        @change="putFunction(func.data)"></v-checkbox>
          </div>
        </template>
        <div class="btn-group groups__buttons sign-up__buttons"
             v-if="group.name['eng'] != 'Admin' && group.name['eng'] != 'New'">
          <button class="btn btn-error" @click="currentGroup = group.id; confirm_modal = true">
            {{ $vuetify.lang.t('$vuetify.delete') }}
          </button>
          <button class="btn btn-secondary" @click="action = 'edit'; newGroup = group; group_create_open();">
            {{ $vuetify.lang.t('$vuetify.edit') }}
          </button>
        </div>
      </v-list-group>
    </v-list>


    <v-dialog v-model="group_create_modal">
      <div class="modal">
        <div class="h1">
          <span v-if="action == 'add'">{{ $vuetify.lang.t('$vuetify.addGroup') }}</span><span
          v-if="action == 'edit'">{{ $vuetify.lang.t('$vuetify.editGroup') }}</span>
        </div>
        <v-form ref="form" @submit.prevent="">
          <!--          <v-text-field v-model="newGroup.name" :label="$vuetify.lang.t('$vuetify.name')" outlined-->
          <!--                        :rules="[$rules.required]"></v-text-field>-->
          <template v-for="lang in Object.keys($vuetify.lang.locales)">
            <v-text-field v-if="['eng', 'rus'].includes(lang)" :key=lang
                          v-model="newGroup.name[lang]"
                          :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined
                          :rules="[$rules.required]"></v-text-field>
            <v-text-field v-else-if="!['en', 'ru'].includes(lang)" :key=lang v-model="newGroup.name[lang]"
                          :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined></v-text-field>
          </template>
          <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
          <div class="sign-up__buttons btn-group">
            <div class="btn btn-secondary" @click="group_create_close">
              {{ $vuetify.lang.t('$vuetify.cancel') }}
            </div>
            <button class="btn btn-primary" @click="addGroup">
              {{ $vuetify.lang.t('$vuetify.save') }}
            </button>
          </div>
        </v-form>
      </div>
    </v-dialog>


    <ConfirmModal v-model="confirm_modal" @confirm="deleteGroup">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
  </div>
</template>

<script>
import ConfirmModal from "@/components/confirmModal";

export default {
  name: "GroupsView",
  components: {ConfirmModal},
  data() {
    return {
      action: "",
      groups: [],
      functions: [],

      newGroup: {
        id: 0,
        name: {}
      },
      currentGroup: 0,
      group_create_modal: false,
      confirm_modal: false,

      alertError: false,
      alertMsg: '',
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.$axios({
        url: this.$hostname + "time-tracking/groups",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.groups = response.data.data.groups
        this.groups.forEach(group => {
          group.name = JSON.parse(group.name)
        })
        this.functions = response.data.data.functions
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    putFunction(func) {
      this.$axios({
        url: this.$hostname + "time-tracking/groups-functions",
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: func,
      }).then((response) => {
        func.id = response.data.data.id
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    addGroup() {
      if (this.$refs.form.validate()) {
        if (this.newGroup.id) {
          this.putGroup()
        } else {
          this.$axios({
            url: this.$hostname + "time-tracking/group",
            method: "POST",
            headers: {
              Authorization:
                "Token " +
                (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
              "Content-Type": "multipart/form-data",
            },
            data: {
              name: JSON.stringify(this.newGroup.name)
            },
          }).then(() => {
            this.loadData()
            this.group_create_close()
          }).catch((response) => {
            this.alertMsg = this.$catch(response)
            this.alertError = true
          })
        }
      } else {
        this.alertMsg = "Заполните обязательные поля"
        this.alertError = true
      }
    },
    putGroup() {
      this.$axios({
        url: this.$hostname + "time-tracking/group",
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.newGroup.id,
          name: JSON.stringify(this.newGroup.name)
        },
      }).then(() => {
        this.loadData()
        this.group_create_close()
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    deleteGroup() {
      this.$axios({
        url: this.$hostname + "time-tracking/group",
        method: "DELETE",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.currentGroup
        },
      }).then(() => {
        this.loadData()
        this.confirm_modal = false
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },

    group_create_open() {
      this.group_create_modal = true;

      if (this.action == "add") {
        this.newGroup = {
          id: 0,
          name: {}
        };
      }
    },

    group_create_close() {
      this.group_create_modal = false;
    },
  }
}
</script>

<style scoped>

</style>