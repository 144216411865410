import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import ru from "@/languages/ru";
import en from "@/languages/en";
import rus from "@/languages/ru";
import eng from "@/languages/en";
import est from "@/languages/est";
import fin from "@/languages/fin";
import DocumentIcon from "@/components/icons/DocumentIcon";
import PhotoIcon from "@/components/icons/PhotoIcon";
import CalendarIcon from "@/components/icons/CalendarIcon";
import SearchIcon from "@/components/icons/SearchIcon";

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {rus, eng, est, fin, en},
        current: 'eng',
        defaultLocale: 'en'
    },
    theme: {
        options: {customProperties: true},
        themes: {
            light: {
                primary: '#557EAE',
                secondary: '#E1EBF8',
                // cards: '#E1EBF8',
                background: '#000000',
                text: {
                    primary: '#E1EBF8',
                    secondary: '#557EAE'
                }
            },
            dark: {
                primary: '#2A3C52',
                secondary: '#344D69',
                text: {
                    primary: '#FFFFFF'
                }
            },
            red: {
                primary: '#ca5e70',
                secondary: '#693434',
                text: {
                    primary: '#FFFFFF'
                }
            }
        },
    },
    icons: {
        values: {
            calendar: {
                component: CalendarIcon
            },
            document: {
                component: DocumentIcon
            },
            photo: {
                component: PhotoIcon
            },
            search: {
                component: SearchIcon
            },
        }
    }
});
