<template>
  <div class="page-wrapper">
    <div class="page-head">
      <h1>{{ $vuetify.lang.t('$vuetify.menu.settings') }}</h1>
    </div>

    <!--    <div class="settings-user">-->
    <!--      <div class="settings__theme">-->
    <!--        <h2>{{ $vuetify.lang.t('$vuetify.settings.selectThemeLabel') }}</h2>-->
    <!--        <div class="settings__colors">-->
    <!--          <input class="colors__radio base" v-model="settings.theme" type="radio" name="color"-->
    <!--                 :value="'light' || 'dark'" id="base" @change="putSettings">-->
    <!--          <label class="colors__label" for="base" style="background-color: #6A9DDA"></label>-->
    <!--          <input class="colors__radio green" v-model="settings.theme" type="radio" name="color"-->
    <!--                 :value="'green'" id="green" @change="putSettings">-->
    <!--          <label class="colors__label" for="green" style="background-color: #51CE65"></label>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="settings__lang">-->
    <!--        <h2>{{ $vuetify.lang.t('$vuetify.settings.selectLangLabel') }}</h2>-->
    <!--        <div class="settings__lang-selected">-->
    <!--          {{ $vuetify.lang.t('$vuetify.settings.languages.' + (settings.language || 'en')) }}-->
    <!--        </div>-->

    <!--        <div class="link" @click="lang_modal = true">Изменить язык</div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="settings-admin" v-if="$parent.admin">
      <router-link to="/settings/groups">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.groups') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/worker-position">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.workerPosition') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/skills">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.skills') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/cards">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.cards') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/contact-position">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.contactPosition') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/cheque-categories">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.chequeCategories') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/reports-categories">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.reportsCategories') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/goals">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.goals') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/terms">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.terms') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/banks">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.banks') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/tax">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.settings.tax') }}
          </div>
        </div>
      </router-link>
      <router-link to="/settings/absence-reasons">
        <div class="cards__single settings__item">
          <div class="settings__icon">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.25534 9.74467V18H9.74466V9.74467H18V8.25534H9.74466V0H8.25534V8.25534H0V9.74467H8.25534ZM8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z"
                    fill="#557EAE"/>
              <path d="M8.25534 9.74467H9.74466V8.25534H8.25534V9.74467Z" fill="#557EAE"/>
            </svg>
          </div>
          <div class="settings__name">
            {{ $vuetify.lang.t('$vuetify.reason') }}
          </div>
        </div>
      </router-link>
    </div>

    <v-dialog v-model="lang_modal">
      <div class="modal">
        <div class="widgets__select lang__select"
             v-for="lang in Object.keys($vuetify.lang.locales)"
             :key="lang"
             @click="settings.language=lang; putSettings()"
        >
          {{ $vuetify.lang.t('$vuetify.settings.languages.' + lang) }}
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "SettingsList",

  data() {
    return {
      settings: {},

      lang_modal: false,
    }
  },

  created() {
    this.loadSettings()
  },

  methods: {
    loadSettings() {
      this.$axios({
        url: this.$hostname + "time-tracking/user-settings",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.settings = response.data.data.data
        // this.$toggleTheme(this.settings.theme, this.settings.language)
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    putSettings() {
      console.log(this.settings)
      this.$axios({
        url: this.$hostname + "time-tracking/user-settings",
        method: "PUT",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: this.settings,
      }).then(() => {
        this.lang_modal = false
        this.$toggleTheme(this.settings.theme, this.settings.language)
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
  }
}
</script>

<style scoped>

</style>