<template>
  <tr class="man-wrap" :class="{ rows__single: type == 'rows' }">
    <div class="cards__single" v-if="type == 'cards'">
      <router-link
        class="link-not-like document__photo"
        :to="$route.path + '/new/'+id"
      >
        <img v-if="photo" :src="$media_url + photo" alt="photo"/>
        <img v-else src="../../assets/img/document-preview.svg" alt="">
        <div class="document__multiple-icon" v-if="multi">
          <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false"
               aria-hidden="true" viewBox="0 0 24 24" data-testid="QueueOutlinedIcon">
            <path
              d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H8V4h12v12zm-7-1h2v-4h4V9h-4V5h-2v4H9v2h4z"></path>
          </svg>
        </div>
      </router-link>

      <ul class="cards__info">
        <li v-for="(value, key) in options" :key="key">
          <span class="bolder">
            {{ $vuetify.lang.t('$vuetify.accounting.card.' + key) }}:</span>
          <span v-if="['created', 'payed', 'expired'].includes(value)">
            {{ $vuetify.lang.t('$vuetify.accounting.card.status' + value[0].toUpperCase() + value.slice(1)) }}
          </span>
          <span v-else-if="key=='category'">
            {{
              value[$vuetify.lang.current] ? value[$vuetify.lang.current] : value['eng'] ? value['eng'] : value['fin']
            }}
          </span>
          <span v-else> {{ value }}  <span v-if="key=='sum'">€</span></span>
        </li>
      </ul>
      <div class="cards__buttons documents__buttons">
        <button v-if="['sales', 'offers'].includes(mode)" class="btn-icon btn-action"
                @click="preview=true; print_document()">
          <picture>
            <document-icon/>
          </picture>
        </button>
        <router-link v-if="$parent.$parent.edit.indexOf('Бухгалтерия') != -1 || mode=='waybills'"
                     class="btn-icon btn-action" :to="$route.path + '/new/'+id">
          <picture>
            <edit-icon/>
          </picture>
        </router-link>
        <button v-if="$parent.$parent.edit.indexOf('Бухгалтерия') != -1 || mode=='waybills'" class="btn-icon btn-action"
                @click="confirm_open(id)">
          <picture>
            <delete-icon/>
          </picture>
        </button>
        <button v-if="zip || path" class="btn-icon btn-action" @click="downloadDocument">
          <!--                :href="$media_url + (zip? zip : path[0])" target="_blank">-->
          <picture>
            <download-icon/>
          </picture>
        </button>

        <button class="btn-icon btn-action"
                v-if="$parent.$parent.edit.indexOf('Бухгалтерия') != -1 && mode=='sales' && options.status != 'payed'"
                @click="payment">
          <picture>
            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false"
                 aria-hidden="true" viewBox="0 0 24 24" data-testid="CreditScoreOutlinedIcon">
              <path
                d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h5v-2H4v-6h18V6c0-1.11-.89-2-2-2zm0 4H4V6h16v2zm-5.07 11.17-2.83-2.83-1.41 1.41L14.93 22 22 14.93l-1.41-1.41-5.66 5.65z"></path>
            </svg>
          </picture>
        </button>

        <button class="btn-icon btn-action"
                v-if="$parent.$parent.edit.indexOf('Бухгалтерия') != -1 && mode=='sales'"
                @click="send_email_open">
          <picture>
            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false"
                 aria-hidden="true" viewBox="0 0 24 24" data-testid="ForwardToInboxOutlinedIcon">
              <path
                d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9v-2H4V8l8 5 8-5v5h2V6c0-1.1-.9-2-2-2zm-8 7L4 6h16l-8 5zm7 4 4 4-4 4v-3h-4v-2h4v-3z"></path>
            </svg>
          </picture>
        </button>
      </div>
    </div>

    <template v-if="type == 'rows'">
      <template v-for="(header) in headers">
        <template v-if="$parent.showHeaders[header]">
          <td v-if="header == 'scan'" :key="header">
            <div class="link-not-like flex document-row__photo">
              <img v-if="photo" :src="$media_url + photo" alt=""/>
              <img v-else src="../../assets/img/document-preview.svg" alt="">
              <div class="document__multiple-icon" v-if="multi">
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false"
                     aria-hidden="true" viewBox="0 0 24 24" data-testid="FileCopyOutlinedIcon">
                  <path
                    d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zM8 21V7h6v5h5v9H8z"></path>
                </svg>
              </div>
            </div>
          </td>
          <!--          v-for="(option, idx) in options"-->
          <td v-else :key="header"
              v-html="['created', 'payed', 'expired'].includes(options[header]) ?
           $vuetify.lang.t('$vuetify.accounting.card.status'+options[header][0].toUpperCase()+options[header].slice(1)) :
           (header == 'category' ? (options[header][$vuetify.lang.current] ? options[header][$vuetify.lang.current] : options[header]['eng'] ? options[header]['eng'] : options[header]['fin']) : options[header] +  (header=='sum' ? '€' : ''))"
          >
          </td>
        </template>
      </template>
      <td class="rows__dot-menu">
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <svg
                v-bind="attrs"
                v-on="on"
                width="20"
                height="5"
                viewBox="0 0 20 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2" cy="2.5" r="2" fill="#2C1521"/>
                <circle cx="10" cy="2.5" r="2" fill="#2C1521"/>
                <circle cx="18" cy="2.5" r="2" fill="#2C1521"/>
              </svg>
            </v-btn>
          </template>
          <div class="rows__dot-menu-content">
            <div class="btn-wrap" v-if="['sales'].includes(mode)" @click="preview=true; print_document()">
              {{ $vuetify.lang.t('$vuetify.preview') }}
              <button class="btn-icon btn-action">
                <picture>
                  <document-icon/>
                </picture>
              </button>
            </div>
            <div class="btn-wrap" v-if="['sales', 'offers'].includes(mode)" @click="downloadDoc">
              {{ $vuetify.lang.t('$vuetify.loadDoc') }}
              <button class="btn-icon btn-action">
                <picture>
                  <document-icon/>
                </picture>
              </button>
            </div>
            <router-link class="btn-wrap link-not-like"
                         v-if="$parent.$parent.edit.indexOf('Бухгалтерия') != -1 || mode=='waybills'"
                         :to="$route.path + '/new/'+id">
              {{ $vuetify.lang.t('$vuetify.edit') }}
              <div class="btn-icon btn-action">
                <picture>
                  <edit-icon/>
                </picture>
              </div>
            </router-link>
            <div class="btn-wrap" v-if="$parent.$parent.edit.indexOf('Бухгалтерия') != -1 || mode=='waybills'"
                 @click="confirm_open(id)">
              {{ $vuetify.lang.t('$vuetify.delete') }}
              <button class="btn-icon btn-action">
                <picture>
                  <delete-icon/>
                </picture>
              </button>
            </div>
            <div class="btn-wrap"
                 v-if="$parent.$parent.edit.indexOf('Бухгалтерия') != -1 && mode=='sales' && options.status != 'payed'"
                 @click="payment">
              {{ $vuetify.lang.t('$vuetify.payment') }}
              <button class="btn-icon btn-action">
                <picture>
                  <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false"
                       aria-hidden="true" viewBox="0 0 24 24" data-testid="CreditScoreOutlinedIcon">
                    <path
                      d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h5v-2H4v-6h18V6c0-1.11-.89-2-2-2zm0 4H4V6h16v2zm-5.07 11.17-2.83-2.83-1.41 1.41L14.93 22 22 14.93l-1.41-1.41-5.66 5.65z"></path>
                  </svg>
                </picture>
              </button>
            </div>
            <div class="btn-wrap" v-if="$parent.$parent.edit.indexOf('Бухгалтерия') != -1 && mode=='sales'"
                 @click="send_email_open">
              {{ $vuetify.lang.t('$vuetify.sendToEmail') }}
              <button class="btn-icon btn-action">
                <picture>
                  <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false"
                       aria-hidden="true" viewBox="0 0 24 24" data-testid="ForwardToInboxOutlinedIcon">
                    <path
                      d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9v-2H4V8l8 5 8-5v5h2V6c0-1.1-.9-2-2-2zm-8 7L4 6h16l-8 5zm7 4 4 4-4 4v-3h-4v-2h4v-3z"></path>
                  </svg>
                </picture>
              </button>
            </div>
          </div>
        </v-menu>
      </td>
    </template>

    <ConfirmModal v-model="confirm_modal" @confirm="tmp_delete">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
    <v-dialog v-model="preview" max-width="700">
      <div>
        <iframe :src="$hostname+path_pdf"
                style="width: 600px; height: 600px; max-width: 100%; max-height: 100%">Ваш браузер не поддерживает
          фреймы
        </iframe>
      </div>
    </v-dialog>
    <v-dialog v-model="send_email_modal" v-if="mode == 'sales'">
      <div class="modal">
        <div class="h1">{{ $vuetify.lang.t('$vuetify.sendToEmail') }}</div>
        <v-combobox
          v-model="send"
          :items="[document.client.email]"
          multiple
          hide-selected
        ></v-combobox>
        <div v-if="alertMsg=='OK'">OK</div>
        <div class="error--text">
          <span v-if="alertError">{{ alertMsg }}</span>
        </div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary text-capitalize" @click="send_email_modal = false">
            {{ $vuetify.lang.t('$vuetify.cancel') }}
          </button>
          <button class="btn btn-primary text-capitalize" @click="send_sale">
            {{ $vuetify.lang.t('$vuetify.send') }}
          </button>
        </div>
      </div>
    </v-dialog>

    <SpinnerLoader :overlay="loader"></SpinnerLoader>
  </tr>
</template>
<script>
import ConfirmModal from "@/components/confirmModal.vue";
import DocumentIcon from "@/components/icons/DocumentIcon";
import EditIcon from "@/components/icons/EditIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import DownloadIcon from "@/components/icons/DownloadIcon";
import SpinnerLoader from "@/components/SpinnerLoader";

export default {
  name: "document-card",

  components: {
    SpinnerLoader,
    DownloadIcon,
    DeleteIcon,
    EditIcon,
    DocumentIcon,
    ConfirmModal,
  },

  // model: {
  //   prop: "checked",
  //   event: "change",
  // },
  props: {
    value: {
      type: Object,
    },
    mode: String,
    // checked: Boolean,
    type: String,
    options: Object,
    headers: Array,
    photo: String,
    zip: String,
    path: [String, Array],
    id: Number,
    document_type: String,
    multi: [Boolean, undefined]
  },
  created() {
    switch (this.mode) {
      case "purchases":
        this.url = "time-tracking/cheque/purchases"
        break
      case "sales":
        this.url = "time-tracking/cheque/sales"
        this.print_url = "time-tracking/cheque/sales/pdf/"
        // this.print_url = "time-tracking/print-sale/"
        break
      case "offers":
        this.url = "time-tracking/offer"
        this.print_url = "time-tracking/print-offer/"
        break
      case "waybills":
        this.url = "time-tracking/waybill"
        break
      default:
        this.url = "time-tracking/accounting/documents"
        break
    }
  },

  data: function () {
    return {
      document: this.value,

      url: "",
      print_url: "",
      // the_checked: this.checked,

      tmp: false,

      preview: false,
      html: '',
      path_pdf: '',

      send_email_modal: false,
      send: [],

      confirm_modal: false,
      tmp_to_delete: "",

      alertMsg: "",
      alertError: false,
      loader: false,
    };
  },
  watch: {
    value() {
      console.log('document props')

      this.document = this.value;
    },
    document() {
      console.log('document emit')
      this.$emit("input", this.document);
    },
  },

  methods: {

    print_document() {
      // let path = ''
      this.loader = true
      this.$axios({
        url: this.$hostname + this.print_url + this.id,
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (response.data.data.html) {
          this.html = response.data.data.html
        }
        this.path_pdf = response.data.data.path
        if (!this.preview)
          this.downloadDoc()
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      }).finally(() => {
        this.loader = false
      })
    },
    downloadDoc() {
      if (this.path_pdf == '') {
        this.print_document()
      }
      if (this.path_pdf !== '' && this.path_pdf != undefined) {
        this.loader = true
        this.$axios({
          url: this.$hostname + this.path_pdf,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', this.path_pdf.substr(this.path_pdf.lastIndexOf('/') + 1));
          document.body.appendChild(fileLink);

          fileLink.click();
        }).finally(() => {
          this.loader = false
        })
      }
    },

    payment() {
      this.$axios({
        url: this.$hostname + "time-tracking/cheque/sales",
        method: "PUT",
        headers: {
          "Authorization": "Token " + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.id,
          status: 'payed'
        },
      }).then(() => {
        this.document.options.status = 'payed'
        this.$emit('payment')
        this.$forceUpdate()
      }).catch((error) => {
        this.alertMsg = this.$catch(error)
        alert(this.alertMsg)
      })
    },

    send_email_open() {
      this.send_email_modal = true
      // this.send_sale()
    },
    send_sale() {
      this.$axios({
        url: this.$hostname + "time-tracking/cheque/sales/send/" + this.id,
        method: "POST",
        headers: {
          "Authorization": "Token " + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
          "Content-Type": "multipart/form-data",
        },
        data: {
          email: JSON.stringify(this.send)
        },
      }).then(() => {
        this.alertMsg = 'ОК'
        this.send_email_modal = false
      }).catch((error) => {
        this.alertMsg = this.$catch(error)
        alert(this.alertMsg)
      })
    },


    confirm_open(id) {
      this.confirm_modal = true;
      this.tmp_to_delete = id;
    },

    confirm_close() {
      this.confirm_modal = false;
      this.tmp_to_delete = "";
    },

    tmp_delete() {
      console.log("delete document")
      this.$axios.delete(
        this.$hostname + this.url,
        {
          data: {
            id: this.id,
          },
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(() => {
          this.alertMsg = "Пользователь удален";
          this.confirm_modal = false;
          this.$destroy();

          // remove the element from the DOM
          this.$el.parentNode.removeChild(this.$el);
        })
        .catch((response) => {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        });
    },

    downloadDocument() {
      let path = ''
      if (this.zip) {
        path = this.zip
      } else {
        if (this.mode == "purchases" && this.path.length > 1) {
          this.downloadPurchase()
          return
        } else
          path = this.path[0]
      }
      // let name = path.substr(path.lastIndexOf('/') + 1, path.lastIndexOf('_')) + path.substr(path.lastIndexOf('.'))
      this.$axios({
        url: this.$hostname + 'media/' + path,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', path);
        document.body.appendChild(fileLink);

        fileLink.click();
      })
    },
    downloadPurchase() {
      this.$axios({
        url: this.$hostname + "time-tracking/cheque/purchases-load/" + this.id,
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        let path = response.data.data.data
        let name = path.substr(path.lastIndexOf('/') + 1, path.lastIndexOf('_')) + path.substr(path.lastIndexOf('.'))
        this.$axios({
          url: this.$hostname + 'media/' + path,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', name);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
      }).catch((error) => {
        this.alertMsg = this.$catch(error)
        console.log(this.alertMsg)
      })
    }
  },
};
</script>
