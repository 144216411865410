<template>
  <div class="page-wrap">
    <div class="page-head">
      <h1 class="h1 dTm-none">{{ $vuetify.lang.t('$vuetify.menu.calendar') }}</h1>
      <button class="btn btn-secondary" @click="addForm = true">+ {{ $vuetify.lang.t('$vuetify.addEvent') }}
      </button>
    </div>
    <div class="calendar">
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn class="btn btn-secondary" outlined @click="setToday">
            {{ $vuetify.lang.t('$vuetify.today') }}
          </v-btn>
          <v-btn fab text small class="btn-secondary" @click="$refs.calendar.prev()">
            <v-icon small> mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn fab text small class="btn-secondary" @click="$refs.calendar.next()">
            <v-icon small> mdi-chevron-right</v-icon>
          </v-btn>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined class="btn btn-secondary" v-bind="attrs" v-on="on">
                <span>{{ $vuetify.lang.t('$vuetify.' + type) }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title> {{ $vuetify.lang.t('$vuetify.day') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>{{ $vuetify.lang.t('$vuetify.month') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar ref="calendar" v-model="focus" color="primary" :events="events"
                    :type="type" :locale="$vuetify.lang.current"
                    :weekdays="weekdays"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
        ></v-calendar>
        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn v-if="selectedEvent.edit" icon @click="openEditForm">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn v-if="selectedEvent.edit" icon @click="confirmDeleteDialog=true">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <div v-html="selectedEvent.description"></div>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="selectedOpen = false">
                {{ $vuetify.lang.t('$vuetify.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </div>

    <v-dialog v-model="addForm">
      <div class="modal modal-big">
        <div class="h1">{{ $vuetify.lang.t('$vuetify.addEvent') }}</div>
        <div>
          <v-form ref="form" :model="newEvent">
<!--            <div v-if="$parent.admin">-->
<!--              <h3>{{ $vuetify.lang.t('$vuetify.forWhom') }}</h3>-->
<!--              <div class="form__row">-->
<!--                <v-checkbox :label="$vuetify.lang.t('$vuetify.forSelf')" v-model="newEvent.self"-->
<!--                            :disabled="newEvent.group !== null || newEvent.profile !== null || newEvent.all"></v-checkbox>-->
<!--                <v-checkbox :label="$vuetify.lang.t('$vuetify.forAll')" v-model="newEvent.all"-->
<!--                            :disabled="newEvent.group !== null || newEvent.profile !== null || newEvent.self"></v-checkbox>-->
<!--              </div>-->
<!--              <div class="form__row" id="group-select">-->
<!--                <v-autocomplete class="group-select" v-model="newEvent.group"-->
<!--                                :label="$vuetify.lang.t('$vuetify.forGroup')"-->
<!--                                :items="groups" item-text="name" item-value="id" :rules="userGroupRules"-->
<!--                                :disabled="newEvent.profile !== null || newEvent.self|| newEvent.all"-->
<!--                                clearable outlined attach-->
<!--                                :menu-props="{-->
<!--                                      bottom: true,-->
<!--                                      offsetY: true,-->
<!--                                      maxHeight: 200,-->
<!--                                      contentClass: 'select-menu',-->
<!--                                  }"-->
<!--                ></v-autocomplete>-->
<!--                <v-autocomplete v-model="newEvent.profile"-->
<!--                                :label="$vuetify.lang.t('$vuetify.forUser')"-->
<!--                                :items="profiles" item-text="label" item-value="id" :rules="userGroupRules"-->
<!--                                :disabled="newEvent.group !== null  || newEvent.self || newEvent.all"-->
<!--                                clearable outlined attach-->
<!--                                :menu-props="{-->
<!--                                      bottom: true,-->
<!--                                      offsetY: true,-->
<!--                                      maxHeight: 200,-->
<!--                                      contentClass: 'select-menu',-->
<!--                                  }"-->
<!--                ></v-autocomplete>-->
<!--              </div>-->
<!--            </div>-->
            <v-text-field v-model="newEvent.name" :label="$vuetify.lang.t('$vuetify.name2')" :rules="[$rules.required]"
                          outlined></v-text-field>
            <h3>{{ $vuetify.lang.t('$vuetify.period') }}</h3>
            <v-checkbox :label="$vuetify.lang.t('$vuetify.allDay')" v-model="newEvent.allDay"></v-checkbox>
            <div class="form__row">
              <v-menu ref="dateStartMenu" v-model="menus.dateStartMenu"
                      :close-on-content-click="false" transition="scale-transition" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="newEvent.date_start ? new Date(newEvent.date_start).toLocaleDateString('ru-Ru') : ''"
                      :label="$vuetify.lang.t('$vuetify.date')" readonly
                      v-bind="attrs"
                      v-on="on" outlined :rules="[$rules.required]"></v-text-field>
                </template>
                <v-date-picker v-model="newEvent.date_start" @input="menus.dateStartMenu = false"
                               no-title first-day-of-week="1"></v-date-picker>
              </v-menu>
              <v-menu v-if="!newEvent.allDay" ref="menuStartTime" v-model="menus.timeStartMenu"
                      :close-on-content-click="false" :return-value.sync="newEvent.time_start"
                      transition="scale-transition" max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="newEvent.time_start" :label="$vuetify.lang.t('$vuetify.time')" readonly
                                v-bind="attrs" v-on="on" outlined :rules="[$rules.required]" required></v-text-field>
                </template>
                <v-time-picker v-if="menus.timeStartMenu" v-model="newEvent.time_start" full-width format="24hr"
                               @click:minute="$refs.menuStartTime.save(newEvent.time_start)" no-title></v-time-picker>
              </v-menu>
            </div>
            <div class="form__row">
              <v-menu ref="dateEndMenu" v-model="menus.dateEndMenu"
                      :close-on-content-click="false"
                      :nudge-right="40" transition="scale-transition" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="newEvent.date_end ? new Date(newEvent.date_end).toLocaleDateString('ru-Ru') : ''"
                      :label="$vuetify.lang.t('$vuetify.endDate')"
                      readonly v-bind="attrs"
                      v-on="on" outlined></v-text-field>
                </template>
                <v-date-picker v-model="newEvent.date_end" @input="menus.dateEndMenu = false" no-title
                               first-day-of-week="1"></v-date-picker>
              </v-menu>
              <v-menu v-if="!newEvent.allDay" ref="menuEndTime" v-model="menus.timeEndMenu"
                      :close-on-content-click="false" :return-value.sync="newEvent.time_end"
                      transition="scale-transition" max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="newEvent.time_end"
                                :label="$vuetify.lang.t('$vuetify.accounting.card.time_end')" readonly
                                v-bind="attrs" v-on="on" outlined required></v-text-field>
                </template>
                <v-time-picker v-if="menus.timeEndMenu" v-model="newEvent.time_end" full-width format="24hr"
                               @click:minute="$refs.menuEndTime.save(newEvent.time_end)" no-title></v-time-picker>
              </v-menu>
            </div>
            <v-autocomplete class="color-select" v-model="newEvent.color" :items="colors"
                            :label="$vuetify.lang.t('$vuetify.color')"
                            :rules="[$rules.required]" outlined attach
                            :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: 200,
                                      contentClass: 'select-menu',
                                  }">
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-chip :color="data.item"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-textarea :label="$vuetify.lang.t('$vuetify.description')" v-model="newEvent.description"
                        outlined></v-textarea>
          </v-form>
          <v-alert v-model="alertError" close-text="Закрыть" color="error" dismissible>
            {{ alertMsg }}
          </v-alert>
        </div>
        <div class="btn-group">
          <button class="btn btn-secondary" @click="addForm=false">{{ $vuetify.lang.t('$vuetify.cancel') }}</button>
          <button class="btn btn-primary" @click="addEvent">{{ $vuetify.lang.t('$vuetify.save') }}</button>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="confirmDeleteDialog" max-width="500">
      <div class="modal">
        <div class="h1">{{ $vuetify.lang.t('$vuetify.deletingEvent') }}</div>
        <div>
          {{ $vuetify.lang.t('$vuetify.confirmDeleteEvent') }}
        </div>
        <div class="btn-group">
          <button class="btn btn-secondary" @click="confirmDeleteDialog = false">
            {{ $vuetify.lang.t('$vuetify.cancel') }}
          </button>
          <button class="btn btn-primary" @click="deleteEvent">{{ $vuetify.lang.t('$vuetify.confirm') }}</button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CalendarView",

  data: () => ({
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    events: [],
    groups: [],
    profiles: [],
    focus: new Date().toDateString(),
    type: 'month',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    newEvent: {
      id: 0,
      self: false,
      all: false,
      group: null,
      profile: null,
      name: '',
      date_start: '',
      time_start: '',
      date_end: '',
      time_end: '',
      allDay: false,
      color: '',
      description: ''
    },
    menus: {
      dateStartMenu: false,
      dateEndMenu: false,
      timeStartMenu: false,
      timeEndMenu: false,
    },
    addForm: false,
    confirmDeleteDialog: false,
    alertError: false,
    alertMsg: "",
  }),

  computed: {
    userGroupRules() {
      const rules = []
      const rule =
          v => !!v || !!this.newEvent.group || !!this.newEvent.profile || this.newEvent.self || this.newEvent.all || this.$vuetify.lang.t('$vuetify.forWhomRule')
      rules.push(rule)
      return rules
    },
  },

  mounted() {
  },
  created() {
    if (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')) {
      this.loadData()
      if (this.$parent.admin) {
        this.loadGroups()
        this.loadProfiles()
      }
    } else {
      this.$router.push({name: "AuthLayout"})
    }
    this.$refs.calendar.checkChange()
  },

  methods: {
    loadData() {
      this.$axios({
        url: this.$hostname + "time-tracking/calendar",
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Token ' + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
        },
      }).then((response) => {
        let edit = response.data.data.edit
        edit.forEach(event => {
          event.edit = true
        })
        let events = response.data.data.data
        events.forEach(event => {
          event.edit = false
        })
        this.events = edit.concat(events)
        this.events.forEach(event => {
          if (event.allDay) {
            event.start = event.start.substr(0, 10)
            if (event.end === null)
              event.end = event.start.substr(0, 10)
            else
              event.end = event.end.substr(0, 10)
          } else {
            event.start = event.start.substr(0, 10) + ' ' + event.start.substr(11, 5)
            if (event.end !== null)
              event.end = event.end.substr(0, 10) + ' ' + event.end.substr(11, 5)
          }
          event.timed = !event.allDay
        })
      }).catch((response) => {
        console.log(response)
        this.alertMsg = this.$catch(response);
        this.alertError = true;
      });
    },
    loadGroups() {
      this.$axios({
        url: this.$hostname + "time-tracking/groups",
        method: "GET",
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Token ' + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
        },
      }).then((response) => {
        this.groups = response.data.data.groups
      }).catch((response) => {
        console.log(response)
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadProfiles() {
      this.$axios({
        url: this.$hostname + "time-tracking/profiles/active",
        method: "GET",
        headers: {
          'Authorization': 'Token ' + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
        },
      }).then((response) => {
        this.profiles = response.data.data.data
        this.profiles.forEach(user => {
          user.label = user.lastname + ' ' + user.name + ', ' + user.position.name
        })
      }).catch((response) => {
        console.log(response)
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    addEvent() {
      if (this.$refs.form.validate()) {
        if (this.newEvent.self || this.newEvent.all) {
          this.newEvent.group = null
          this.newEvent.profile = null
        }
        this.newEvent.start = null
        this.newEvent.end = null
        if (this.newEvent.allDay) {
          this.newEvent.start = this.newEvent.date_start + ' ' + '00:00'
          if (this.newEvent.date_end !== '')
            this.newEvent.end = this.newEvent.date_end + ' ' + '00:00'
        } else {
          this.newEvent.start = this.newEvent.date_start + ' ' + this.newEvent.time_start
          if (this.newEvent.date_end !== '')
            this.newEvent.end = this.newEvent.date_end + ' ' + this.newEvent.time_end
        }
        if (this.newEvent.id) {
          this.putEvent()
        } else {
          this.$axios({
            url: this.$hostname + "time-tracking/calendar",
            method: "POST",
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Token ' + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
            },
            data: {
              self: this.newEvent.self,
              group: this.newEvent.group,
              profile: this.newEvent.profile,
              name: this.newEvent.name,
              start: this.newEvent.start,
              end: this.newEvent.end,
              allDay: this.newEvent.allDay,
              color: this.newEvent.color,
              description: this.newEvent.description
            }
          }).then(() => {
            this.loadData()
            this.$refs.calendar.checkChange()
            this.closeAddForm()
          }).catch((response) => {
            console.log(response)
            this.alertMsg = this.$catch(response)
            this.alertError = true
          })
        }
      } else {
        this.alertMsg = "Заполните все необходимые поля"
        this.alertError = true
      }
    },
    putEvent() {
      this.$axios({
        url: this.$hostname + "time-tracking/calendar",
        method: "PUT",
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Token ' + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
        },
        data: {
          id: this.newEvent.id,
          group: this.newEvent.group,
          profile: this.newEvent.profile,
          name: this.newEvent.name,
          start: this.newEvent.start,
          end: this.newEvent.end,
          allDay: this.newEvent.allDay,
          color: this.newEvent.color,
          description: this.newEvent.description
        }
      }).then(() => {
        this.loadData()
        this.$refs.calendar.checkChange()
        this.closeAddForm()
      }).catch((response) => {
        console.log(response)
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    deleteEvent() {
      this.$axios({
        url: this.$hostname + "time-tracking/calendar",
        method: "DELETE",
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Token ' + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
        },
        data: {
          id: this.selectedEvent.id
        }
      }).then(() => {
        this.loadData()
        this.confirmDeleteDialog = false
      }).catch((response) => {
        console.log(response)
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    closeAddForm() {
      this.addForm = false
      this.newEvent = {
        self: false,
        group: null,
        profile: null,
        name: '',
        date_start: '',
        time_start: '',
        date_end: '',
        time_end: '',
        allDay: false,
        color: '',
        description: ''
      }
    },
    openEditForm() {
      this.newEvent = this.selectedEvent
      this.newEvent.date_start = this.newEvent.start.substr(0, 10)
      this.newEvent.time_start = this.newEvent.start.substr(11, 5)
      if (this.newEvent.end !== null) {
        this.newEvent.date_end = this.newEvent.end.substr(0, 10)
        this.newEvent.time_end = this.newEvent.end.substr(11, 5)
      } else {
        this.newEvent.date_end = ''
        this.newEvent.time_end = ''
      }
      if (this.newEvent.group == null && this.newEvent.profile == null) {
        this.newEvent.all = true
      }
      this.addForm = true
    },
    viewDay({date}) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
  },
}
</script>

<style scoped>

</style>