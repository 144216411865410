<template>
  <div class="page-wrapper">
    <div class="page-head">
      <h1>{{ $vuetify.lang.t('$vuetify.menu.useful') }}</h1>

      <div class="page-head__buttons">
        <button v-if="$parent.admin" class="btn-secondary btn" @click="action='add'; linkCreateOpen()">
          + {{ $vuetify.lang.t('$vuetify.addLink') }}
        </button>
      </div>
    </div>

    <div class="accordions">
      <div class="accordion cards__single" v-for="(items, category, id) in links" :key="category">
        <!--        <div class="accordion__title" @click="open = category!=open ? category : 0">-->
        <div class="accordion__title" @click="switchAccordion(id); open.active = category!=open.active ? category : 0">
          <div class="accordion__title-text h2">
            {{ items.name[$vuetify.lang.current] }}
          </div>
          <div class="accordion__title-icon">
            <svg v-if="open.active == category" width="21" height="11" viewBox="0 0 21 11" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 10L10.5 1L19.5 10" stroke="#88B1E1" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            <svg v-if="open.active != category" width="21" height="11" viewBox="0 0 21 11" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 1L10.5 10L19.5 1" stroke="#88B1E1" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="accordion__content useful-accordion__content"
             :class="{ 'accordion__content--close': open.active != category }"
             :style="'height: ' + (open.active == category ? open.height + 'px' : '0') + ';'"
        >
          <div class="contacts-accordion__text" :ref="'accordion-' + id">
            <div class="useful-item" v-for="link in items.data" :key="link.id">
              <div class="useful-item__header">
                <div class="useful-item__title bolder" v-if="!link.link">{{ link.name }}</div>
                <a class="link-not-like useful-item__title bolder cp" v-else
                   :href="link.link.indexOf('http') == 0 ? link.link : ('//'+link.link)"
                   target="_blank">{{ link.name }}</a>
                <div class="useful-item__icon cp" v-if="link.address">
                  <a target="_blank"
                     :href="'https://www.google.com/maps/search/?api=1&query='+link.address.replaceAll(' ', '+')">
                    <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false"
                         aria-hidden="true" viewBox="0 0 24 24" data-testid="DirectionsOutlinedIcon">
                      <path
                          d="m22.43 10.59-9.01-9.01c-.75-.75-2.07-.76-2.83 0l-9 9c-.78.78-.78 2.04 0 2.82l9 9c.39.39.9.58 1.41.58.51 0 1.02-.19 1.41-.58l8.99-8.99c.79-.76.8-2.02.03-2.82zm-10.42 10.4-9-9 9-9 9 9-9 9zM8 11v4h2v-3h4v2.5l3.5-3.5L14 7.5V10H9c-.55 0-1 .45-1 1z"></path>
                    </svg>
                  </a>
                </div>
                <div class="useful-item__icon cp" v-if="$parent.admin"
                     @click="newLink = link; linkCreateOpen()">
                  <edit-icon/>
                </div>
                <div class="useful-item__icon cp" v-if="$parent.admin"
                     @click="linkToDelete = link.id; confirmLinkModal = true">
                  <delete-icon/>
                </div>
              </div>
              <div class="useful-item__comment" v-if="link.comment">{{ link.comment }}</div>
            </div>
            <div class="btn-group">
              <div class="btn-primary btn useful__btn" @click="categoryEditOpen(category)">
                {{ $vuetify.lang.t('$vuetify.edit') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="linkCreateModal">
      <div class="modal">
        <div class="h1">
          <span v-if="action == 'add'"> {{ $vuetify.lang.t('$vuetify.addLink') }}</span>
          <span v-if="action == 'edit'"> {{ $vuetify.lang.t('$vuetify.editLink') }}</span>
        </div>
        <v-form ref="form" @submit.prevent="">
          <div class="form__row align-baseline">
            <v-autocomplete v-model="newLink.category"
                            :items="categories" return-object :item-text="$vuetify.lang.current"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.category')" outlined attach
                            :rules="[$rules.required]"
                            :menu-props="{
          bottom: true,
          offsetY: true,
          maxHeight: width<1024 ? 200 : 800,
          contentClass: 'select-menu',
          }"></v-autocomplete>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button class="btn-icon btn-action" @click="categoryCreateOpen"
                        v-bind="attrs"
                        v-on="on">
                  +
                </button>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.addCategory") }}</span>
            </v-tooltip>
          </div>
          <v-text-field v-model="newLink.name" :label="$vuetify.lang.t('$vuetify.name2')" outlined
                        :rules="[$rules.required]"></v-text-field>
          <v-text-field v-model="newLink.link" :label="$vuetify.lang.t('$vuetify.link')" outlined></v-text-field>
          <v-text-field v-model="newLink.address" :label="$vuetify.lang.t('$vuetify.address')" outlined></v-text-field>
          <v-textarea v-model="newLink.comment" :label="$vuetify.lang.t('$vuetify.comment')" outlined></v-textarea>
          <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
          <div class="sign-up__buttons btn-group">
            <div class="btn btn-secondary" @click="linkCreateClose">
              {{ $vuetify.lang.t('$vuetify.cancel') }}
            </div>
            <button class="btn btn-primary" @click="addLink">
              <span>{{ $vuetify.lang.t('$vuetify.save') }}</span>
            </button>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <v-dialog v-model="categoryCreateModal">
      <div class="modal">
        <div class="h1">{{ $vuetify.lang.t('$vuetify.addCategory') }}</div>
        <v-form ref="category" @submit.prevent="">
          <template v-for="lang in Object.keys($vuetify.lang.locales)">
            <v-text-field v-if="['eng', 'rus'].includes(lang)" :key=lang
                          v-model="newCategory[lang]"
                          :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined
                          :rules="[$rules.required]"></v-text-field>
            <v-text-field v-else-if="!['en', 'ru'].includes(lang)" :key=lang v-model="newCategory[lang]"
                          :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined></v-text-field>
          </template>
          <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
          <div class="sign-up__buttons btn-group">
            <div class="btn btn-secondary" @click="categoryCreateModal = false">
              {{ $vuetify.lang.t('$vuetify.cancel') }}
            </div>
            <button class="btn btn-primary" @click="addCategory">
              <span>{{ $vuetify.lang.t('$vuetify.save') }}</span>
            </button>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <ConfirmModal v-model="confirmLinkModal" @confirm="deleteLink">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
  </div>
</template>

<script>
import EditIcon from "@/components/icons/EditIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import ConfirmModal from "@/components/confirmModal";

export default {
  name: "UsefulLinksList",
  components: {ConfirmModal, DeleteIcon, EditIcon},
  data: function () {
    return {
      links: [],
      categories: [],
      open: {
        active: "",
        height: 0,
      },

      newLink: {
        category: {}
      },
      linkCreateModal: false,
      action: "",
      linkToDelete: null,
      confirmLinkModal: false,

      newCategory: {},
      oldCategory: {},
      categoryCreateModal: false,

      alertMsg: "",
      alertError: "",

      width: screen.width,
    }
  },
  created() {
    if (this.$parent.type == 'client') {
      this.$router.push({name: "404"})
    } else {
      this.loadData()
    }
  },

  methods: {
    loadData() {
      this.$axios({
        url: this.$hostname + "time-tracking/links",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        this.links = response.data.data.data
        this.categories = response.data.data.categories
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },

    addLink() {
      if (this.$refs.form.validate()) {
        if (this.newLink.id) {
          this.putLink()
        } else {
          this.newLink.category = JSON.stringify(this.newLink.category)
          this.$axios({
            url: this.$hostname + "time-tracking/links",
            method: "POST",
            headers: {
              Authorization:
                  "Token " +
                  (localStorage.getItem("auth_token") ||
                      sessionStorage.getItem("auth_token")),
              "Content-Type": "multipart/form-data",
            },
            data: this.newLink,
          }).then(() => {
            this.loadData()
            this.linkCreateClose()
          }).catch((response) => {
            this.newLink.category = JSON.parse(this.newLink.category)
            this.alertMsg = this.$catch(response)
            this.alertError = true
          })
        }
      } else {
        this.alertMsg = this.$vuetify.lang.t('$vuetify.fillForm')
        this.alertError = true
      }
    },
    putLink() {
      this.newLink.category = JSON.stringify(this.newLink.category)
      this.$axios({
        url: this.$hostname + "time-tracking/links",
        method: "PUT",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: this.newLink,
      }).then(() => {
        this.loadData()
        this.linkCreateClose()
      }).catch((response) => {
        this.newLink.category = JSON.parse(this.newLink.category)
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    deleteLink() {
      this.$axios({
        url: this.$hostname + "time-tracking/links",
        method: "DELETE",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {id: this.linkToDelete},
      }).then(() => {
        this.loadData()
        this.confirmLinkModal = false
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    linkCreateOpen() {
      this.linkCreateModal = true
    },
    linkCreateClose() {
      this.linkCreateModal = false
      this.newLink = {
        category: {}
      }
    },

    addCategory() {
      if (this.$refs.category.validate()) {
        if (this.oldCategory.eng) {
          this.putCategory()
        }
        this.categories.push(this.newCategory)
        this.newLink.category = this.newCategory
        this.categoryCreateModal = false
      } else {
        this.alertMsg = this.$vuetify.lang.t('$vuetify.fillForm')
        this.alertError = true
      }
    },
    putCategory() {
      let editable = []
      this.links[this.oldCategory.eng].data.forEach(item => {
        editable.push(item.id)
      })
      this.$axios({
        url: this.$hostname + "time-tracking/links/categories",
        method: "PUT",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          category: JSON.stringify(this.newCategory),
          links: JSON.stringify(editable)
        }
      }).then(() => {
        this.loadData()
        this.oldCategory = {}
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    categoryCreateOpen() {
      let locales = Object.keys(this.$vuetify.lang.locales)
      locales.forEach(loc => {
        this.newCategory[loc] = ""
      })
      this.categoryCreateModal = true
    },
    categoryEditOpen(category) {
      this.newCategory = this.categories.find(cat => {
        return cat.eng == category
      })
      this.oldCategory = structuredClone(this.newCategory)
      this.categoryCreateModal = true
    },


    switchAccordion(i) {
      let a = this.$refs["accordion-" + i][0].clientHeight;
      this.open.height = a;
    },

  },
}
</script>

<style scoped>

</style>