<template>
  <div>
    <div class="big-card">
      <h2>{{ $vuetify.lang.t('$vuetify.addNew') }}</h2>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
              :placeholder="$vuetify.lang.t('$vuetify.title')"
              v-model="newNew.title"
              :rules="titleRules"
              required
              outlined
          ></v-text-field>
          <editor
              v-model="newNew.text"
              api-key="660vq0feuhjyub7s7o01nh9an48e4eq55ucbneldw55cr22l"
              :init="editorInit"
          />
        </v-form>
        <v-alert
            v-model="alertError"
            close-text="CLose"
            color="error"
            dismissible
        >
          {{ alertMsg }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <div class="news-card__file-input">
          <img v-if="newNew.photo_path" class="img-small" :src="$media_url + newNew.photo_path" alt="">
          <v-file-input
              class="file-input"
              v-model="photoField"
              accept=".jpg, .png, .jpeg"
              :placeholder="$vuetify.lang.t('$vuetify.uploadNewsImage')"
              :prepend-icon="newNew.photo_path ? ' ':   '$photo'"
          ></v-file-input>
        </div>

        <v-spacer></v-spacer>

        <div class="btn-group">
          <button class="btn btn-secondary link-not-like" @click="$router.go(-1)">
            {{ $vuetify.lang.t('$vuetify.back') }}
          </button>
          <button class="btn btn-primary" @click="addNew">{{ $vuetify.lang.t('$vuetify.publish') }}!</button>
        </div>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "news-add",

  components: {editor: Editor},

  props: {
    id: String
  },

  data: function () {
    return {
      photoField: null,
      photoDialog: false,
      titleRules: [(v) => !!v || "Необходимо ввести заголовок"],
      newNew: {
        id: 0,
        title: "",
        text: "",
        photo_path: null,
      },
      alertMsg: "",

      editorInit: {
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
            "undo redo | formatselect | bold italic backcolor | link | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | image | removeformat | help",
        file_picker_types: "image",
        relative_urls: false,
        image_prepend_url: this.$hostname,
        images_upload_base_path: this.$hostname,
        image_description: false,
        // image_dimensions: false,
        file_picker_callback: function (callback, value, meta) {
          document.querySelector(".tox-lock.tox-button").click();
          console.log(callback);
          console.log(value);
          console.log(meta);
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.onchange = function () {
            var file = this.files[0];
            console.log(file);
            var reader = new FileReader();
            reader.onload = function () {
              const axios = require("axios");
              // чтение файла в formData
              let fd = new FormData();
              if (file !== null) {
                fd.append("file", file);
              }
              axios({
                method: "post",
                url: "http://65.21.185.61/time-tracking/images/upload",
                // url: "http://127.0.0.1:8000/time-tracking/images/upload",
                // url: "https://shielded-plateau-96200.herokuapp.com/time-tracking/images/upload",
                headers: {
                  Authorization:
                      "Token " +
                      (localStorage.getItem("auth_token") ||
                          sessionStorage.getItem("auth_token")),
                },
                data: fd,
              }).then((response) => {
                console.log(response.data.data);
                document.querySelector(
                    ".tox-control-wrap .tox-textfield"
                ).value = response.data.data;
              });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
      },

      action: 'add',

      alertError: false,
    };
  },

  mounted() {
    if (this.id != '') {
      this.action = 'edit'
      this.loadData();
    }
  },

  methods: {
    loadData() {
      this.$axios({
        url: this.$hostname + "time-tracking/news/" + this.id,
        method: "GET",
      }).then((response) => {
        this.newNew = response.data.data.data[0];
        console.log(this.currentNew);
      });
    },
    closePhotoForm() {
      // if (this.newNew.photo_path !== null)
      //   this.currentNew.photo_path = '/news/' + this.newNew.photo_path.name
      this.photoDialog = false;
    },
    confirmNew() {
      if (this.action == 'add') {
        this.addNew()
      } else {
        this.editNew();
      }
    },
    addNew() {
      if (this.$refs.form.validate()) {
        if (this.newNew.id > 0) this.editNew();
        else {
          // чтение файла в formData
          let fd = new FormData();
          let photo = this.photoField;
          if (this.photoField !== undefined) {
            fd.append("image", photo);
          }
          fd.append("title", this.newNew.title);
          fd.append("text", this.newNew.text);
          this.$axios({
            method: "post",
            url: this.$hostname + "time-tracking/news",
            headers: {
              Authorization:
                  "Token " +
                  (localStorage.getItem("auth_token") ||
                      sessionStorage.getItem("auth_token")),
            },
            data: fd,
          }).then((response) => {
            console.log(response.data.data);
            this.photoDialog = false;
            this.$router.push("/news");
          });
        }
      } else {
        this.alertError = true;
        this.alertMsg = "Заполните обязательные поля";
      }
    },

    editNew() {
      if (this.$refs.form.validate()) {
        // чтение файла в formData
        let fd = new FormData();
        let photo = this.photoField;
        if (this.photoField !== undefined) {
          fd.append("image", photo);
        }
        fd.append("title", this.newNew.title);
        fd.append("text", this.newNew.text);
        fd.append("active", true);
        fd.append("id", this.id);
        this.$axios({
          method: "put",
          url: this.$hostname + "time-tracking/news",
          headers: {
            Authorization:
                "Token " +
                (localStorage.getItem("auth_token") ||
                    sessionStorage.getItem("auth_token")),
          },
          data: fd,
        }).then((response) => {
          console.log(response.data.data);
          this.photoDialog = false;
          this.$router.push("/news");
        });
      } else {
        this.alertError = true;
        this.alertMsg = "Заполните обязательные поля";
      }
    },
  },
};
</script>
