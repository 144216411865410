<template>
  <div class="cards__single document-card">
    <h1>
      {{ $vuetify.lang.t('$vuetify.accounting.' + mode + '.new') }}

    </h1>
    <div class="document-card__grid">
      <div class="document-card__documents">
        <div class="document-card__documents-body">
          <div class="h2"> {{ $vuetify.lang.t('$vuetify.accounting.' + mode + '.scan') }}</div>
<!--          <ul v-if="id">-->
<!--            <li v-for="(document, i) in newDocument.path" :key="document" class="flex justify-between">-->
<!--              <img class="document-row__photo document-row__photo-big"-->
<!--                   v-if="(['png', 'jpg', 'jpeg', 'JPG', 'PNG', 'JPEG'].includes(document.substr(document.lastIndexOf('.') + 1)))"-->
<!--                   :src="$media_url + document" alt="photo"-->
<!--                   @click="lightboxItems.push($media_url + document); index=0"/>-->

<!--              <a v-else :href="$hostname + 'media/'+document" target="_blank">-->
<!--                {{-->
<!--                  document.substr(document.lastIndexOf('/') + 1, document.lastIndexOf('_') + 1) + document.substr(document.lastIndexOf('.'))-->
<!--                }}-->
<!--              </a>-->
<!--              <v-btn icon @click="deleteDoc(i)">-->
<!--                <delete-icon/>-->
<!--              </v-btn>-->
<!--            </li>-->
<!--          </ul>-->
          <div v-if="id" class="document__slider">
            <v-carousel>
              <v-carousel-item
                  v-for="(document, i) in newDocument.path"
                  :key="i"
                  :src="$media_url + document"
                  @click.stop="lightboxItems.push($media_url + document); index=0"
              >
                <v-btn icon @click="deleteDoc(i)">
                  <delete-icon style="width: 30px; height: 30px"/>
                </v-btn>
              </v-carousel-item>
            </v-carousel>
          </div>
          <ul>
            <li v-for="document in documents" :key="document.name"><a>{{ document.name }}</a>
            </li>
          </ul>
        </div>


        <v-file-input accept=".jpg, .png, .pdf, .doc, .docx" class="file-input" v-model="documents" multiple
                      :placeholder="$vuetify.lang.t('$vuetify.downloadDocument')" prepend-icon="$document">
        </v-file-input>
      </div>

      <div class="document-card__fields form">
        <v-form ref="form" @submit.prevent="">
          <div class="h2"> {{ $vuetify.lang.t('$vuetify.accounting.' + mode + '.info') }}</div>
          <div v-if="mode == 'contracts' || mode == 'documents'">
            <v-text-field v-model="newDocument.name"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.name')" outlined
                          :rules="[$rules.required]"></v-text-field>
          </div>
          <div v-if="mode=='garage'">
            <v-autocomplete v-model="newDocument.options.car"
                            :items="cars" return-object item-text="name" item-value="id"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.car')" outlined attach
                            :rules="[$rules.required]"
                            :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"></v-autocomplete>
          </div>
          <div v-if="mode == 'property' || mode == 'garage'">
            <v-text-field v-model="newDocument.options.cost" type="number"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.cost')" outlined
                          :rules="[$rules.required]"></v-text-field>
          </div>
          <div v-if="mode == 'property'">
            <v-text-field v-model="newDocument.options.status"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.status')" outlined
                          :rules="[$rules.required]"></v-text-field>
          </div>
          <div v-if="['contracts', 'documents', 'property', 'garage'].includes(mode)">
            <v-menu ref="date" v-model="dateMenu" :close-on-content-click="false"
                    transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :value="new Date(newDocument.create_date).toLocaleDateString('ru-Ru')"
                              :label="$vuetify.lang.t('$vuetify.accounting.card.date'+mode)" append-icon="$calendar"
                              v-bind="attrs"
                              v-on="on"
                              outlined readonly :rules="[$rules.required]">
                </v-text-field>
              </template>
              <v-date-picker v-model="newDocument.create_date" no-title
                             @input="dateMenu = false" first-day-of-week="1"
                             :allowed-dates="allowedDates"></v-date-picker>
            </v-menu>
          </div>
          <div v-if="mode == 'reports'">
            <v-select v-model="newDocument.options.category"
                      :items="reportsCategories" item-value="name" item-text="name"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.category')" outlined attach
                      :rules="[$rules.required]"
                      :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }">
              <template v-slot:selection="{ item }">
                {{
                  item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
                }}
              </template>
              <template v-slot:item="{ item }">
                {{
                  item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
                }}
              </template>
            </v-select>
          </div>
          <div v-if="mode == 'property'">
            <v-select v-model="newDocument.options.object"
                      :items="objects" return-object item-text="name" item-value="id"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.address')" outlined attach
                      :rules="[$rules.required]"
                      :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"></v-select>
          </div>
          <div v-if="mode == 'contracts'">
            <v-select v-model="newDocument.client"
                      :items="clients" item-value="id" item-text="name"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.concluded')" outlined attach
                      :rules="[$rules.required]"
                      :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"></v-select>
          </div>
          <div v-if="mode == 'extracts'">
            <v-select v-model="newDocument.options.bank"
                      :items="banks" item-value="name" item-text="name"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.bank')" outlined attach
                      :rules="[$rules.required]"
                      :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"></v-select>
          </div>
          <div v-if="mode == 'reports' || mode == 'extracts'">
            <v-menu ref="date" v-model="dateMenu" :close-on-content-click="false"
                    transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :value="dateRange"
                              :label="$vuetify.lang.t('$vuetify.accounting.card.period')" append-icon="$calendar"
                              v-bind="attrs"
                              v-on="on"
                              outlined readonly :rules="[$rules.required]">
                </v-text-field>
              </template>
              <v-date-picker v-model="newDocument.options.period" no-title first-day-of-week="1"
                             @input="newDocument.options.period.length == 2 ? dateMenu = false : dateMenu = true"
                             :allowed-dates="allowedDates" range></v-date-picker>
            </v-menu>
          </div>
          <div v-if="mode == 'purchases'">
            <v-text-field v-model="newDocument.number" type="number"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.numberBill')" outlined></v-text-field>
            <v-select v-model="newDocument.category" outlined attach
                      :label="$vuetify.lang.t('$vuetify.accounting.card.category')"
                      :items="chequeCategories" item-text="name" item-value="id"
                      :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"
                      :rules="[$rules.required]"
            >
              <template v-slot:selection="{ item }">
                {{
                  item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
                }}
              </template>
              <template v-slot:item="{ item }">
                {{
                  item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
                }}
              </template>
            </v-select>
            <v-select v-model="newDocument.payment_method"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.paymentMethod')"
                      :items="paymentMethods" item-text="name" item-value="value" outlined attach
                      :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"
            >
              <template v-slot:selection="{ item }">
                {{ $vuetify.lang.t('$vuetify.accounting.card.' + item.value) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $vuetify.lang.t('$vuetify.accounting.card.' + item.value) }}
              </template>
            </v-select>
            <v-select v-model="newDocument.bundle"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.bundle')"
                      :items="selectBundle" item-text="name" item-value="value" attach
                      outlined
                      :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"
            >
              <template v-slot:selection="{ item }">
                {{
                  $vuetify.lang.t('$vuetify.accounting.card.bundle' + item.value[0].toUpperCase() + item.value.substr(1))
                }}
              </template>
              <template v-slot:item="{ item }">
                {{
                  $vuetify.lang.t('$vuetify.accounting.card.bundle' + item.value[0].toUpperCase() + item.value.substr(1))
                }}
              </template>
            </v-select>

            <v-menu ref=" date
            " v-model="menu.dateReceipt" :close-on-content-click="false"
                    transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :value="new Date(newDocument.date_receipt).toLocaleDateString('ru-Ru')"
                              :label="$vuetify.lang.t('$vuetify.accounting.card.dateReceipt')" append-icon="$calendar"
                              v-bind="attrs"
                              v-on="on"
                              outlined readonly :rules="[$rules.required]">
                </v-text-field>
              </template>
              <v-date-picker v-model="newDocument.date_receipt" no-title
                             @input="menu.dateReceipt = false" first-day-of-week="1"
                             :allowed-dates="allowedDates"></v-date-picker>
            </v-menu>
            <v-menu ref="date" v-model="dateMenu" :close-on-content-click="false"
                    transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :value="new Date(newDocument.date).toLocaleDateString('ru-Ru')"
                              :label="$vuetify.lang.t('$vuetify.accounting.card.date'+mode)" append-icon="$calendar"
                              v-bind="attrs"
                              v-on="on"
                              outlined readonly :rules="[$rules.required]">
                </v-text-field>
              </template>
              <v-date-picker v-model="newDocument.date" no-title
                             @input="dateMenu = false" first-day-of-week="1"
                             :allowed-dates="allowedDates"></v-date-picker>
            </v-menu>
            <div class="form__row">
              <v-text-field v-model="newDocument.price" type="number"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.sum')"
                            outlined :rules="[$rules.required, $rules.positiveNumbers]">
              </v-text-field>
              <v-select v-model="newDocument.tax" outlined attach
                        :label="$vuetify.lang.t('$vuetify.accounting.card.tax')"
                        :items="taxes" item-text="tax" item-value="id"
                        :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"
                        :rules="[$rules.required]"
              ></v-select>
            </div>
            <v-text-field v-model="newDocument.place"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.place')"
                          outlined :rules="[$rules.required]">
            </v-text-field>
            <v-textarea v-model="newDocument.comment"
                        :label="$vuetify.lang.t('$vuetify.accounting.card.comment')" outlined></v-textarea>
          </div>
          <div v-if="mode == 'waybills'">
            <v-text-field v-model="newDocument.auto_mark"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.auto')"
                          outlined :rules="[$rules.required]"></v-text-field>
            <v-select v-model="newDocument.goal"
                      :label="$vuetify.lang.t('$vuetify.accounting.card.goal')"
                      :items="goals" item-text="name" item-value="id" outlined attach
                      :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: width<1024 ? 200 : 800,
                                      contentClass: 'select-menu',
                                  }"
                      :rules="[$rules.required]"
            >
              <template v-slot:selection="{ item }">
                {{
                  item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
                }}
              </template>
              <template v-slot:item="{ item }">
                {{
                  item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
                }}
              </template>
            </v-select>
            <div class="form__row">
              <v-menu ref="dateStart" v-model="menu.dateStart" :close-on-content-click="false"
                      transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="new Date(newDocument.date_start).toLocaleDateString('ru-Ru')"
                                :label="$vuetify.lang.t('$vuetify.accounting.card.date_start')" append-icon="$calendar"
                                v-bind="attrs"
                                v-on="on"
                                outlined readonly :rules="[$rules.required]">
                  </v-text-field>
                </template>
                <v-date-picker v-model="newDocument.date_start" no-title
                               @input="menu.dateStart = false" first-day-of-week="1"
                               :allowed-dates="allowedDates"></v-date-picker>
              </v-menu>
              <v-menu ref="dateEnd" v-model="menu.dateEnd" :close-on-content-click="false"
                      transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="new Date(newDocument.date_end).toLocaleDateString('ru-Ru')"
                                :label="$vuetify.lang.t('$vuetify.accounting.card.date_end')" append-icon="$calendar"
                                v-bind="attrs" v-on="on"
                                outlined readonly :rules="[$rules.required]">
                  </v-text-field>
                </template>
                <v-date-picker v-model="newDocument.date_end" no-title
                               @input="menu.dateEnd = false" first-day-of-week="1"
                               :allowed-dates="allowedDates"></v-date-picker>
              </v-menu>
            </div>
            <div class="form__row">
              <v-menu ref="timeStart" v-model="menu.timeStart" :close-on-content-click="false"
                      :nudge-right="40" :return-value.sync="newDocument.time_start" transition="scale-transition"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="newDocument.time_start"
                                :label="$vuetify.lang.t('$vuetify.accounting.card.time_start')" readonly v-bind="attrs"
                                v-on="on" outlined :rules="[$rules.required]" required></v-text-field>
                </template>
                <v-time-picker v-if="menu.timeStart" v-model="newDocument.time_start" full-width format="24hr"
                               @click:minute="$refs.timeStart.save(newDocument.time_start)"></v-time-picker>
              </v-menu>
              <v-menu ref="timeEnd" v-model="menu.timeEnd" :close-on-content-click="false"
                      transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="newDocument.time_end"
                                :label="$vuetify.lang.t('$vuetify.accounting.card.time_end')" readonly
                                v-bind="attrs" v-on="on" outlined :rules="[$rules.required]" required></v-text-field>
                </template>
                <v-time-picker v-if="menu.timeEnd" v-model="newDocument.time_end" full-width format="24hr"
                               @click:minute="$refs.timeEnd.save(newDocument.time_end)"></v-time-picker>
              </v-menu>
            </div>
            <div class="form__row">
              <v-text-field v-model="newDocument.kilometrage_start" type="number"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.mileageStart')"
                            outlined :rules="[$rules.required]"></v-text-field>
              <v-text-field v-model="newDocument.kilometrage_end" type="number"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.mileageEnd')"
                            outlined :rules="[$rules.required]"></v-text-field>
              <v-text-field v-model="mileageTotal" type="number"
                            :label="$vuetify.lang.t('$vuetify.accounting.card.mileageTotal')"
                            outlined readonly :rules="[$rules.required]"></v-text-field>
            </div>
            <v-text-field v-model="newDocument.departure"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.departure')"
                          outlined :rules="[$rules.required]"></v-text-field>
            <v-text-field v-model="newDocument.destination"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.destination')"
                          outlined :rules="[$rules.required]"></v-text-field>
            <v-text-field v-if="$route.name == 'addBookkeeping'" v-model="newDocument.user_profile.label"
                          :label="$vuetify.lang.t('$vuetify.accounting.card.worker')"
                          outlined readonly disabled></v-text-field>
          </div>
        </v-form>
      </div>
    </div>

    <div class="document-card__bottom">
      <div></div>
      <div v-if="alertError" class="error--text">{{ alertMsg }}</div>
      <div class="btn-group">
        <div class="btn-primary btn" @click="uploadFile">{{ $vuetify.lang.t('$vuetify.save') }}</div>
        <div class="btn btn-danger" @click="tmp_delete">{{ $vuetify.lang.t('$vuetify.delete') }}</div>
      </div>
    </div>

    <CoolLightBox
        :items="lightboxItems"
        :index="index"
        @close="index = null; lightboxItems=[]">
    </CoolLightBox>

    <SpinnerLoader :overlay="loader"></SpinnerLoader>
  </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader";
import DeleteIcon from "@/components/icons/DeleteIcon";

export default {
  name: 'add-document',
  components: {
    DeleteIcon,
    SpinnerLoader,
  },
  props: {
    mode: [String],
    id: [String, Number]
  },

  data: function () {
    return {
      url: "",

      reportsCategories: [],
      chequeCategories: [],
      banks: [],
      clients: [],
      objects: [],
      taxes: [],
      paymentMethods: [
        {name: this.$vuetify.lang.t('$vuetify.accounting.card.bankCard'), value: "bankCard"},
        {name: this.$vuetify.lang.t('$vuetify.accounting.card.bankTransfer'), value: "bankTransfer"},
        {name: this.$vuetify.lang.t('$vuetify.accounting.card.cash'), value: "cash"},
      ],
      goals: [],
      selectBundle: [
        {name: this.$vuetify.lang.t('$vuetify.accounting.card.bundleSingle'), value: 'single'},
        {name: this.$vuetify.lang.t('$vuetify.accounting.card.bundleMultiple'), value: 'multiple'},
      ],
      cars: [],

      documents: [],
      newDocument: {
        name: '',
        path: [],
        options: {},
        create_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        client: null,
      },
      loadedFiles: 0,

      lightboxItems: [],
      index: null,

      dateMenu: false,
      menu: {
        dateReceipt: false,
        dateStart: false,
        dateEnd: false,
        timeStart: false,
        timeEnd: false,
      },
      alertMsg: "",
      alertError: false,
      loader: false,

      width: screen.width,
    }
  },

  computed: {
    dateRange: function () {
      if (this.newDocument.options.period != undefined) {
        return this.newDocument.options.period.join(' ~ ')
        // return "" || (new Date(this.newDocument.options.period[0]).toLocaleDateString('ru-Ru') + '-' + new Date(this.newDocument.options.period[1]).toLocaleDateString('ru-Ru'))
      } else {
        return ""
      }
    },
    mileageTotal: function () {
      return Number(this.newDocument.kilometrage_end - this.newDocument.kilometrage_start).toFixed(1)
    }
  },

  watch: {
    loadedFiles: function (val) {
      if (val == this.documents.length) {
        if (this.id != undefined)
          this.editDocument()
        else
          this.addDocument()
      }
    },
  },

  created() {
    if (this.mode == 'contracts') {
      this.url = "time-tracking/accounting/documents-client/" + this.mode
    } else {
      this.url = "time-tracking/accounting/documents/" + this.mode
    }
    switch (this.mode) {
      case 'reports':
        this.newDocument.name = 'Report'
        this.loadReportsCategories()
        break
      case 'extracts':
        this.newDocument.name = 'Extract'
        this.loadBanks()
        break
      case 'contracts':
        this.loadClients()
        break
      case 'property':
        this.newDocument.name = 'Property'
        this.loadObjects()
        break
      case 'garage':
        this.newDocument.name = 'Garage'
        this.loadGarage()
        break
      case 'purchases':
        this.url = "time-tracking/cheque/purchases"
        this.newDocument = {
          id: 0,
          user_profile: '',
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          date_receipt: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          category: '',
          tax: '',
          payment_method: '',
          number: '',
          place: '',
          price: '',
          bundle: '',
          comment: '',
          path: [],
        }
        this.loadChequeCategories()
        this.loadTaxes()
        break
      case 'waybills':
        this.url = "time-tracking/waybill"
        this.newDocument = {
          date_start: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          date_end: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          departure: '',
          destination: '',
          kilometrage_start: '',
          kilometrage_end: '',
          time_start: '',
          time_end: '',
          goal: '',
          auto_mark: '',
          auto_type: '',
          auto_fuel: '',
          path: [],
        }
        this.loadGoals()
        break
    }
    if (this.id) {
      this.loadData()
    }
  },

  methods: {
    allowedDates: val => val <= new Date().toISOString(),

    loadData() {
      this.$axios({
        url: this.$hostname + this.url + "/" + this.id,
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.newDocument = response.data.data.data
        this.newDocument.path = JSON.parse(this.newDocument.path)
        if (this.newDocument.user_profile)
          this.newDocument.user_profile.label = this.newDocument.user_profile.name + " " + this.newDocument.user_profile.lastname
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadReportsCategories() {
      this.$axios({
        url: this.$hostname + "time-tracking/accounting/reports-categories",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.reportsCategories = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadChequeCategories() {
      this.$axios({
        url: this.$hostname + "time-tracking/cheque/categories",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.chequeCategories = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadBanks() {
      this.$axios({
        url: this.$hostname + "time-tracking/accounting/bank",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.banks = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadClients() {
      this.$axios({
        url: this.$hostname + "time-tracking/accounting/clients",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.clients = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadObjects() {
      this.$axios({
        url: this.$hostname + "time-tracking/accounting/objects",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.objects = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadTaxes() {
      this.$axios({
        url: this.$hostname + "time-tracking/accounting/taxes",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.taxes = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadGoals() {
      this.$axios({
        url: this.$hostname + "time-tracking/waybill/goal",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.goals = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadGarage() {
      this.$axios({
        url: this.$hostname + "time-tracking/accounting/garage",
        method: "get",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        if (response.data.data.data) {
          this.cars = response.data.data.data
        }
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      }).then(() => {
        this.loader = false
      })
    },

    addDocument() {
      this.loader = true
      this.newDocument.path = JSON.stringify(this.newDocument.path)
      this.newDocument.options = JSON.stringify(this.newDocument.options)
      this.$axios({
        url: this.$hostname + this.url,
        method: 'POST',
        headers: {
          "Authorization": "Token " + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
          "Content-Type": "multipart/form-data",
        },
        data: this.newDocument
        // data: {
        //   name: this.newDocument.name,
        //   create_date: this.newDocument.create_date,
        //   path: JSON.stringify(this.newDocument.path),
        //   options: JSON.stringify(this.newDocument.options),
        //   client: this.newDocument.client
        // }
      }).then(() => {
        if (this.$route.name == 'addWaybill') {
          this.$router.push({name: 'waybills', params: {mode: this.mode}})
        } else
          this.$router.push({name: 'documents', params: {mode: this.mode}})
      }).catch(error => {
        this.newDocument.path = JSON.parse(this.newDocument.path)
        this.newDocument.options = JSON.parse(this.newDocument.options)
        this.alertMsg = this.$catch(error)
        this.alertError = true
      }).then(() => {
        this.loader = false
      })
    },
    editDocument() {
      this.loader = true
      this.newDocument.path = JSON.stringify(this.newDocument.path)
      if (this.mode == 'purchases') {
        this.newDocument.user_profile = this.newDocument.user_profile.id ? this.newDocument.user_profile.id : this.newDocument.user_profile
        this.newDocument.tax = this.newDocument.tax.id ? this.newDocument.tax.id : this.newDocument.tax
        this.newDocument.category = this.newDocument.category.id ? this.newDocument.category.id : this.newDocument.category
      } else {
        this.newDocument.options = JSON.stringify(this.newDocument.options)
        this.newDocument.client = this.newDocument.client ? this.newDocument.client.id : ""
      }
      this.$axios({
        url: this.$hostname + this.url,
        method: 'PUT',
        headers: {
          "Authorization": "Token " + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
          "Content-Type": "multipart/form-data",
        },
        data: this.newDocument,
        // data: {
        //   id: this.id,
        //   name: this.newDocument.name,
        //   create_date: this.newDocument.create_date,
        //   path: JSON.stringify(this.newDocument.path),
        //   options: JSON.stringify(this.newDocument.options),
        //   client: this.newDocument.client ? this.newDocument.client.id : ""
        // }
      }).then(() => {
        this.$router.push({name: 'documents', params: {mode: this.mode}})
      }).catch(error => {
        this.newDocument.path = JSON.parse(this.newDocument.path)
        this.newDocument.options = JSON.parse(this.newDocument.options)
        this.alertMsg = this.$catch(error)
        this.alertError = true
      }).then(() => {
        this.loader = false
      })
    },
    deleteDocument() {
      this.$axios({
        url: this.$hostname + this.url,
        method: "DELETE",
        headers: {
          "Authorization": "Token " + (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')),
          "Content-Type": "multipart/form-data",
        },
        data: {
          "id": this.newDocument.id
        },
      }).then(() => {
        console.log("Документ удален")
        this.$router.push({name: 'documents', params: {mode: this.mode}})
      }).catch(error => {
        this.alertMsg = this.$catch(error)
        this.alertError = true
      })
    },

    uploadFile() {
      if (this.documents.length > 0) {
        if (this.$refs.form.validate()) {
          this.loader = true
          this.documents.forEach(doc => {
            this.$axios({
              url: this.$hostname + "time-tracking/images/upload/" + (this.mode == 'purchases' ? "cheque" : "accounting"),
              method: "POST",
              headers: {
                Authorization:
                    "Token " +
                    (localStorage.getItem("auth_token") ||
                        sessionStorage.getItem("auth_token")),
                "Content-Type": "multipart/form-data",
              },
              data: {
                file: doc,
              },
            })
                .then((response) => {
                  this.newDocument.path.push(response.data.data)
                  this.loadedFiles++
                })
                .catch((error) => {
                  alert("Ошибка загрузки файла: " + error.message);
                });
          })
        } else {
          this.alertMsg = "Заполните обязательные поля"
          this.alertError = true
        }
      } else {
        if (this.id != undefined) {
          this.editDocument()
        } else if (this.mode == 'waybills') {
          this.addDocument()
        } else {
          this.alertMsg = "Прикрепите файлы"
          this.alertError = true
        }
      }
    },
    deleteDoc(i) {
      this.newDocument.path.splice(i, 1)
    },

    tmp_delete() {
      if (this.id == undefined) {
        this.$router.push({name: 'documents', params: {mode: this.mode}})
      } else {
        this.deleteDocument()
      }
    },
  }

}
</script>