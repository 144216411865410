<template>
  <div class="sign-up">
    <div v-if="step == 1" class="sign-up__card cards__single" key="step1">
      <div class="sign-up__header">
        <div class="sign-up__title h1">
          {{ $vuetify.lang.t('$vuetify.signup.hello') }}
        </div>
      </div>
      <v-form ref="form" class="sign-up__form" @submit.prevent="">
        <v-text-field
          v-model="profile.name"
          :label="$vuetify.lang.t('$vuetify.signup.name')"
          outlined
          :rules="[$rules.required, $rules.text]"
        ></v-text-field>
        <v-text-field
          v-model="profile.lastname"
          :label="$vuetify.lang.t('$vuetify.signup.lastname')"
          outlined
          :rules="[$rules.required, $rules.text]"
        ></v-text-field>
        <v-text-field
          v-model="profile.phone"
          :label="$vuetify.lang.t('$vuetify.phone')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="profile.citizenship"
          :label="$vuetify.lang.t('$vuetify.citizenship')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <div class="sign-up__buttons btn-group">
          <button
            class="btn btn-secondary"
            @click="deleteUser"
          >
            {{ $vuetify.lang.t('$vuetify.signup.cancel') }}
          </button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step == 2" class="sign-up__card cards__single" key="step2">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.wantTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.wantSubtitle') }}
        </div>
      </div>
      <v-form class="sign-up__form" ref="form" @submit.prevent="">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="profile.birthdate ? new Date(profile.birthdate).toLocaleDateString('ru-Ru') : ''"
              :label="$vuetify.lang.t('$vuetify.signup.birthdate')"
              append-icon="$calendar"
              v-bind="attrs"
              v-on="on"
              outlined
              :rules="[$rules.required]"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="profile.birthdate"
            no-title first-day-of-week="1"
            :max="new Date().toISOString()"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
        <div class="form__radio">
          <div class="form__radio-single custom-radio">
            <input
              class="custom-radio__hide"
              type="radio"
              name="from-finland"
              value="yes"
              id="from-finland-yes"
              v-model="options.address"
            />
            <label class="custom-radio__button" for="from-finland-yes">
              <span></span
              ></label>
            <label for="from-finland-yes">{{ $vuetify.lang.t('$vuetify.fromFinland') }}</label>
          </div>
          <div class="form__radio-single custom-radio">
            <input
              class="custom-radio__hide"
              type="radio"
              name="from-finland"
              value="no"
              id="from-finland-no"
              v-model="options.address"
            />
            <label class="custom-radio__button" for="from-finland-no"
            ><span></span
            ></label>
            <label for="from-finland-no">{{ $vuetify.lang.t('$vuetify.fromAnotherCountry') }}</label>
          </div>
        </div>
        <v-text-field
          v-model="profile.social_code_fin"
          :label="$vuetify.lang.t('$vuetify.socialCodeFin')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="profile.tax_number"
          :label="$vuetify.lang.t('$vuetify.taxNumber')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="profile.bank_account"
          :label="$vuetify.lang.t('$vuetify.signup.bankAccount')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step == 3" class="sign-up__card cards__single" key="step3">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.whereTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.whereSubtitle') }}
        </div>
      </div>
      <v-form ref="form" class="sign-up__form" @submit.prevent="">
        <div class="form__title h2" v-if="options.address == 'no'">
          {{ $vuetify.lang.t('$vuetify.signup.addressFin') }}
        </div>
        <div class="form__subtitle" v-if="options.address == 'yes'">
          {{ $vuetify.lang.t('$vuetify.signup.addressFromFin') }}
        </div>
        <div class="form__row form__address">
          <v-text-field
            class="address__street"
            v-model="profile.address_fin.street"
            :label="$vuetify.lang.t('$vuetify.street')"
            outlined
            :rules="[$rules.required]"
          ></v-text-field>
          <v-text-field
            class="address__house"
            v-model="profile.address_fin.house"
            :label="$vuetify.lang.t('$vuetify.house')"
            outlined
            :rules="[$rules.required]"
          ></v-text-field>
          <v-text-field
            class="address__flat"
            v-model="profile.address_fin.flat"
            :label="$vuetify.lang.t('$vuetify.flat')"
            outlined
          ></v-text-field>
          <v-text-field
            class="address__entrance"
            v-model="profile.address_fin.entrance"
            :label="$vuetify.lang.t('$vuetify.entrance')"
            outlined
          ></v-text-field>
        </div>
        <div class="form__row form__address">
          <v-text-field
            class="address__index"
            v-model="profile.address_fin.index"
            :label="$vuetify.lang.t('$vuetify.index')"
            outlined
            :rules="[$rules.required]"
          ></v-text-field>
          <v-text-field
            class="address__city"
            v-model="profile.address_fin.city"
            :label="$vuetify.lang.t('$vuetify.city')"
            outlined
            :rules="[$rules.required]"
          ></v-text-field>
        </div>
        <div v-if="options.address == 'no'">
          <div class="h2">{{ $vuetify.lang.t('$vuetify.signup.addressOwn') }}</div>
          <div class="form__row form__address">
            <v-text-field
              class="address__street"
              v-model="profile.address_own.street"
              :label="$vuetify.lang.t('$vuetify.street')"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
            <v-text-field
              class="address__house"
              v-model="profile.address_own.house"
              :label="$vuetify.lang.t('$vuetify.house')"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
            <v-text-field
              class="address__flat"
              v-model="profile.address_own.flat"
              :label="$vuetify.lang.t('$vuetify.flat')"
              outlined
            ></v-text-field>
            <v-text-field
              class="address__entrance"
              v-model="profile.address_own.entrance"
              :label="$vuetify.lang.t('$vuetify.entrance')"
              outlined
            ></v-text-field>
          </div>
          <div class="form__row form__address">
            <v-text-field
              class="address__index"
              v-model="profile.address_own.index"
              :label="$vuetify.lang.t('$vuetify.index')"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
            <v-text-field
              class="address__city"
              v-model="profile.address_own.city"
              :label="$vuetify.lang.t('$vuetify.city')"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </div>
          <v-text-field
            class="address__country"
            v-model="profile.address_own.country"
            :label="$vuetify.lang.t('$vuetify.country')"
            outlined
            :rules="[$rules.required]"
          ></v-text-field>
        </div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step == 4" class="sign-up__card cards__single" key="step4">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.additionalTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.additionalSubtitle') }}
        </div>
      </div>
      <v-form ref="form" class="sign-up__form form__grid" @submit.prevent="">
        <div class="form__lang">
          <div class="h2">{{ $vuetify.lang.t('$vuetify.languages') }}</div>
          <v-checkbox v-model="profile.english" label="English"></v-checkbox>
          <v-checkbox v-model="profile.estonian" label="Eesti"></v-checkbox>
          <v-checkbox v-model="profile.finnish" label="Suomalainen"></v-checkbox>
          <v-checkbox v-model="profile.russian" label="Русский"></v-checkbox>
          <div class="form__row">
            <v-checkbox
              v-model="other_language_check"
              :label="$vuetify.lang.t('$vuetify.other')" hide-details
            ></v-checkbox>
            <v-text-field
              hide-details
              v-model="profile.other_language"
              :disabled="!other_language_check"
            ></v-text-field>
          </div>
        </div>
        <div class="form__auto">
          <div class="h2">{{ $vuetify.lang.t('$vuetify.additional') }}</div>
          <v-checkbox
            v-model="profile.auto"
            :label="$vuetify.lang.t('$vuetify.haveOwnCar')"
          ></v-checkbox>
          <v-checkbox
            v-model="profile.tool"
            :label="$vuetify.lang.t('$vuetify.haveOwnTools')"
          ></v-checkbox>
          <div>{{ $vuetify.lang.t('$vuetify.driverLicenseCat') }}</div>
          <div class="form__auto-category">
            <v-checkbox
              v-model="profile.auto_category"
              label="A"
              value="A"
            ></v-checkbox>
            <v-checkbox
              v-model="profile.auto_category"
              label="B"
              value="B"
            ></v-checkbox>
            <v-checkbox
              v-model="profile.auto_category"
              label="C"
              value="C"
            ></v-checkbox>
            <v-checkbox
              v-model="profile.auto_category"
              label="D"
              value="D"
            ></v-checkbox>
            <v-checkbox
              v-model="profile.auto_category"
              label="E"
              value="E"
            ></v-checkbox>
            <v-checkbox
              v-model="profile.auto_category"
              label="F"
              value="F"
            ></v-checkbox>
          </div>
        </div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step == 5" class="sign-up__card cards__single" key="step5">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.skillsTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.skillsSubtitle') }}
        </div>
      </div>
      <v-form ref="form" class="sign-up__form" @submit.prevent="">
        <div class="h2">{{ $vuetify.lang.t('$vuetify.signup.mainSpec') }}</div>
        <v-select
          v-model="profile.position"
          :items="positions"
          outlined attach
          item-text="name"
          item-value="id"
          :placeholder="$vuetify.lang.t('$vuetify.signup.selectSpec')"
          :menu-props="{
            bottom: true,
            offsetY: true,
            maxHeight: 200,
            contentClass: 'select-menu',
          }"
        >
          <template v-slot:selection="{ item }">
            {{
              item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
            }}
          </template>
          <template v-slot:item="{ item }">
            {{
              item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
            }}
          </template>
        </v-select>
        <v-autocomplete v-if="admin" v-model="currentGroup"
                        :label="$vuetify.lang.t('$vuetify.systemRole')"
                        :items="groups" item-text="name" item-value="id" return-object outlined attach
                        :menu-props="{
                  bottom: true,
                  // offsetY: true,
                  maxHeight: 200,
                  contentClass: 'select-menu',
                }"
                        :rules="[$rules.required]">
          <template v-slot:item="{ item }">
            {{
              item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
            }}
          </template>
          <template v-slot:selection="{ item }">
            {{
              item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
            }}
          </template>
        </v-autocomplete>
        <div class="form__clothes">
          <div class="h2">{{ $vuetify.lang.t('$vuetify.sizeUniform') }}</div>
          <div class="form__grid">
            <div class="form__row">
              <span class="clothes__title">{{ $vuetify.lang.t('$vuetify.shirt') }}</span>
              <v-text-field v-model="profile.shirt" :label="$vuetify.lang.t('$vuetify.size')" outlined></v-text-field>

            </div>
            <div class="form__row">
              <span class="clothes__title">{{ $vuetify.lang.t('$vuetify.pants') }}</span>
              <v-text-field v-model="profile.pants" :label="$vuetify.lang.t('$vuetify.size')" outlined></v-text-field>

            </div>
            <div class="form__row">
              <span class="clothes__title">{{ $vuetify.lang.t('$vuetify.jacket') }}</span>
              <v-text-field v-model="profile.jacket" :label="$vuetify.lang.t('$vuetify.size')" outlined></v-text-field>

            </div>
            <div class="form__row">
              <span class="clothes__title">{{ $vuetify.lang.t('$vuetify.boots') }}</span>
              <v-text-field v-model="profile.boots" :label="$vuetify.lang.t('$vuetify.size')" outlined></v-text-field>
            </div>
            <div class="form__row">
              <span class="clothes__title">{{ $vuetify.lang.t('$vuetify.gloves') }}</span>
              <v-text-field v-model="profile.gloves" :label="$vuetify.lang.t('$vuetify.size')" outlined></v-text-field>

            </div>
          </div>
        </div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <!--    <div v-if="step == 6" class="sign-up__card cards__single" key="step6">-->
    <!--      <div class="sign-up__header">-->
    <!--        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.skillsTitle') }}</div>-->
    <!--        <div class="sign-up__subtitle">-->
    <!--          {{ $vuetify.lang.t('$vuetify.signup.skillsSubtitle') }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <v-form ref="form" class="sign-up__form" @submit.prevent="">-->
    <!--        <div class="sign-up__grid form__skills">-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.paintingWorks') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.outdoor_painting" :title="$vuetify.lang.t('$vuetify.outdoor')">-->
    <!--              </RatingItem>-->
    <!--              <RatingItem v-model="profile.skills.indoor_painting" :title="$vuetify.lang.t('$vuetify.indoor')">-->
    <!--              </RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.plastering') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.manual_plastering" :title="$vuetify.lang.t('$vuetify.manual')">-->
    <!--              </RatingItem>-->
    <!--              <RatingItem v-model="profile.skills.hardware_plastering"-->
    <!--                          :title="$vuetify.lang.t('$vuetify.hardware')">-->
    <!--              </RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.facade_plasteir') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.facade_plasteir_chain" :title="$vuetify.lang.t('$vuetify.chain')">-->
    <!--              </RatingItem>-->
    <!--              <RatingItem v-model="profile.skills.facade_plasteir_plasterboard"-->
    <!--                          :title="$vuetify.lang.t('$vuetify.plasterboard')"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.alignment') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.alignment"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.carpentry') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.carpentry_simple" :title="$vuetify.lang.t('$vuetify.simple')">-->
    <!--              </RatingItem>-->
    <!--              <RatingItem v-model="profile.skills.carpentry_formwork" :title="$vuetify.lang.t('$vuetify.formwork')">-->
    <!--              </RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.suspendedCeilings') }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.suspended_ceilings_giprok"-->
    <!--                          :title="$vuetify.lang.t('$vuetify.giprok')">-->
    <!--              </RatingItem>-->
    <!--              <RatingItem v-model="profile.skills.suspended_ceilings_panels"-->
    <!--                          :title="$vuetify.lang.t('$vuetify.panels')">-->
    <!--              </RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.partitionWalls') }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.partition_walls"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">-->
    <!--              {{ $vuetify.lang.t('$vuetify.tileWaterproofing') }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.tile_waterproofing"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="sign-up__buttons btn-group">-->
    <!--          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>-->
    <!--          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>-->
    <!--        </div>-->
    <!--      </v-form>-->
    <!--    </div>-->
    <!--    <div v-if="step == 7" class="sign-up__card cards__single" key="step7">-->
    <!--      <div class="sign-up__header">-->
    <!--        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.skillsTitle') }}</div>-->
    <!--        <div class="sign-up__subtitle">-->
    <!--          {{ $vuetify.lang.t('$vuetify.signup.skillsSubtitle') }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <v-form ref="form" class="sign-up__form" @submit.prevent="">-->
    <!--        <div class="sign-up__grid form__skills">-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.floor') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.floor_parquet" :title="$vuetify.lang.t('$vuetify.parquet')">-->
    <!--              </RatingItem>-->
    <!--              <RatingItem v-model="profile.skills.floor_laminate" :title="$vuetify.lang.t('$vuetify.laminate')">-->
    <!--              </RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.masonry') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.masonry"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.concrete_works') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.concrete_works"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.concrete_installation') }}-->

    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.concrete_installation"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.reinforcement') }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.reinforcement"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.roof') }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.roof_tiles" :title="$vuetify.lang.t('$vuetify.tiles')">-->
    <!--              </RatingItem>-->
    <!--              <RatingItem v-model="profile.skills.roof_metal" :title="$vuetify.lang.t('$vuetify.metal')">-->
    <!--              </RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.bitumen') }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.bitumen_roof" :title="$vuetify.lang.t('$vuetify.bitumen_roof')">-->
    <!--              </RatingItem>-->
    <!--              <RatingItem v-model="profile.skills.bitumen_plinth"-->
    <!--                          :title="$vuetify.lang.t('$vuetify.bitumen_plinth')"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.welding') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.welding"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{-->
    <!--                $vuetify.lang.t('$vuetify.workers.skills.diamond_cutting_drilling')-->
    <!--              }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.diamond_cutting_drilling"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="sign-up__buttons btn-group">-->
    <!--          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>-->
    <!--          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>-->
    <!--        </div>-->
    <!--      </v-form>-->
    <!--    </div>-->
    <!--    <div v-if="step == 8" class="sign-up__card cards__single" key="step8">-->
    <!--      <div class="sign-up__header">-->
    <!--        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.skillsTitle') }}</div>-->
    <!--        <div class="sign-up__subtitle">-->
    <!--          {{ $vuetify.lang.t('$vuetify.signup.skillsSubtitle') }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <v-form ref="form" class="sign-up__form" @submit.prevent="">-->
    <!--        <div class="sign-up__grid form__skills">-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.control_transport') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.control_loader"-->
    <!--                          :title="$vuetify.lang.t('$vuetify.workers.skills.loader')"></RatingItem>-->
    <!--              <RatingItem v-model="profile.skills.control_lift"-->
    <!--                          :title="$vuetify.lang.t('$vuetify.workers.skills.lift')"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{-->
    <!--                $vuetify.lang.t('$vuetify.workers.skills.control_tractor')-->
    <!--              }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.control_tractor"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.linoleum') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.linoleum"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.epoxy_coating') }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.epoxy_coating"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.electrics') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.electrics"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.plumbing') }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.plumbing"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{-->
    <!--                $vuetify.lang.t('$vuetify.workers.skills.sandblasting')-->
    <!--              }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.sandblasting"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{-->
    <!--                $vuetify.lang.t('$vuetify.workers.skills.dismantling')-->
    <!--              }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.dismantling"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.asbestos') }}-->
    <!--            </div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.asbestos"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="skills__item">-->
    <!--            <div class="skills__title">{{ $vuetify.lang.t('$vuetify.workers.skills.cleaning') }}</div>-->
    <!--            <div class="skills__types">-->
    <!--              <RatingItem v-model="profile.skills.cleaning"></RatingItem>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="sign-up__buttons btn-group">-->
    <!--          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>-->
    <!--          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>-->
    <!--        </div>-->
    <!--      </v-form>-->
    <!--    </div>-->
    <div v-if="step == 6" class="sign-up__card cards__single" key="step9">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.almostTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.almostSubtitle') }}
        </div>
      </div>
      <v-form
        ref="form"
        class="sign-up__form sign-up__files"
        @submit.prevent=""
      >
        <!--        <div class="h2">{{ $vuetify.lang.t('$vuetify.passport') }}</div>-->
        <!--        <v-file-input-->
        <!--            v-model="files.passport.file"-->
        <!--            accept=".jpg, .png, .pdf, .doc, .docx"-->
        <!--            :placeholder=" $vuetify.lang.t('$vuetify.downloadDocument')"-->
        <!--            prepend-icon="$document"-->
        <!--            :rules="[$rules.fileSize]"-->
        <!--        ></v-file-input>-->
        <div class="h2">{{ $vuetify.lang.t('$vuetify.signup.photo') }}</div>
        <div class="form__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.photoSubtitle') }}
        </div>
        <v-file-input
          v-model="files.photo.file"
          accept=".jpg, .png, .jpeg"
          :placeholder=" $vuetify.lang.t('$vuetify.downloadPhoto')"
          prepend-icon="$photo"
          :rules="[$rules.fileSize]"
        ></v-file-input>
        <!--        <div class="h2">{{ $vuetify.lang.t('$vuetify.residence') }}</div>-->
        <!--        <div class="form__subtitle">-->
        <!--          {{ $vuetify.lang.t('$vuetify.signup.residenceSubtitle') }}-->
        <!--        </div>-->
        <!--        <v-file-input-->
        <!--            v-model="files.residence.file"-->
        <!--            accept=".jpg, .png, .pdf, .doc, .docx"-->
        <!--            :placeholder=" $vuetify.lang.t('$vuetify.downloadDocument')"-->
        <!--            prepend-icon="$document"-->
        <!--            :rules="[$rules.fileSize]"-->
        <!--        ></v-file-input>-->
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <!--          <button class="btn btn-primary" @click="nextStep">-->
          <button class="btn btn-primary" @click="uploadFile(files.photo)">
            {{ $vuetify.lang.t('$vuetify.forward') }}
          </button>

        </div>
      </v-form>
    </div>
    <!--    <div v-if="step == 10" class="sign-up__card cards__single" key="step10">-->
    <!--      <div class="sign-up__header">-->
    <!--        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.cardsTitle') }}</div>-->
    <!--        <div class="sign-up__subtitle">-->
    <!--          {{ $vuetify.lang.t('$vuetify.signup.cardsSubtitle') }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <v-form ref="form" class="sign-up__form" @submit.prevent="">-->
    <!--        <template v-for="i in cardsQuantity">-->
    <!--          <div :key="i">-->
    <!--            <div class="form__row form__card">-->
    <!--              <div class="h2">{{ profile.cards[i - 1].name }}</div>-->
    <!--              <button class="btn-icon" v-if="i - 1" @click="deleteCard(i - 1)">-->
    <!--                <svg-->
    <!--                    width="12"-->
    <!--                    height="14"-->
    <!--                    viewBox="0 0 12 14"-->
    <!--                    fill="none"-->
    <!--                    xmlns="http://www.w3.org/2000/svg"-->
    <!--                >-->
    <!--                  <path-->
    <!--                      d="M7.2 2.19971C7.2 1.88145 7.07357 1.57622 6.84853 1.35118C6.62348 1.12614 6.31826 0.999707 6 0.999707C5.68174 0.999707 5.37652 1.12614 5.15147 1.35118C4.92643 1.57622 4.8 1.88145 4.8 2.19971H4C4 1.66927 4.21071 1.16057 4.58579 0.785493C4.96086 0.410421 5.46957 0.199707 6 0.199707C6.53043 0.199707 7.03914 0.410421 7.41421 0.785493C7.78929 1.16057 8 1.66927 8 2.19971H11.6C11.7061 2.19971 11.8078 2.24185 11.8828 2.31686C11.9579 2.39188 12 2.49362 12 2.59971C12 2.70579 11.9579 2.80753 11.8828 2.88255C11.8078 2.95756 11.7061 2.99971 11.6 2.99971H11.1568L10.12 11.9837C10.0749 12.3736 9.88796 12.7333 9.59487 12.9944C9.30177 13.2555 8.92292 13.3998 8.5304 13.3997H3.4696C3.07708 13.3998 2.69823 13.2555 2.40513 12.9944C2.11204 12.7333 1.92514 12.3736 1.88 11.9837L0.8424 2.99971H0.4C0.30638 2.99974 0.215714 2.96693 0.143793 2.907C0.0718717 2.84706 0.0232516 2.7638 0.00639992 2.67171L0 2.59971C0 2.49362 0.0421428 2.39188 0.117157 2.31686C0.192172 2.24185 0.293913 2.19971 0.4 2.19971H7.2ZM10.3504 2.99971H1.6488L2.6744 11.8917C2.69698 12.0867 2.79048 12.2666 2.93712 12.3972C3.08375 12.5278 3.27327 12.5998 3.4696 12.5997H8.5304C8.72659 12.5996 8.91592 12.5275 9.06238 12.3969C9.20885 12.2664 9.30224 12.0866 9.3248 11.8917L10.3504 2.99971ZM4.8 4.99971C4.996 4.99971 5.16 5.12371 5.1936 5.28691L5.2 5.35011V10.2501C5.2 10.4429 5.0208 10.5997 4.8 10.5997C4.604 10.5997 4.44 10.4757 4.4064 10.3125L4.4 10.2493V5.35091C4.4 5.15731 4.5792 5.00051 4.8 5.00051V4.99971ZM7.2 4.99971C7.396 4.99971 7.56 5.12371 7.5936 5.28691L7.6 5.35011V10.2501C7.6 10.4429 7.4208 10.5997 7.2 10.5997C7.004 10.5997 6.84 10.4757 6.8064 10.3125L6.8 10.2493V5.35091C6.8 5.15731 6.9792 5.00051 7.2 5.00051V4.99971Z"-->
    <!--                      fill="#2C1521"-->
    <!--                  />-->
    <!--                </svg>-->
    <!--              </button>-->
    <!--            </div>-->
    <!--            <div class="form__row form__card">-->
    <!--              <v-text-field-->
    <!--                  class="card__number"-->
    <!--                  v-model="profile.cards[i - 1].number"-->
    <!--                  :label=" $vuetify.lang.t('$vuetify.cardNumber')"-->
    <!--                  outlined-->
    <!--                  :rules="[$rules.required]"-->
    <!--              ></v-text-field>-->
    <!--              <v-menu-->
    <!--                  ref="menuCard"-->
    <!--                  v-model="menuCard[i]"-->
    <!--                  :close-on-content-click="false"-->
    <!--                  transition="scale-transition"-->
    <!--              >-->
    <!--                <template v-slot:activator="{ on, attrs }">-->
    <!--                  <v-text-field-->
    <!--                      class="card__date"-->
    <!--                      :value="profile.cards[i - 1].due_date ? new Date(profile.cards[i - 1].due_date).toLocaleDateString('ru-Ru') : ''"-->
    <!--                      :label=" $vuetify.lang.t('$vuetify.dueDate')"-->
    <!--                      readonly-->
    <!--                      v-bind="attrs"-->
    <!--                      v-on="on"-->
    <!--                      outlined-->
    <!--                      :rules="[$rules.required]"-->
    <!--                  ></v-text-field>-->
    <!--                </template>-->
    <!--                <v-date-picker-->
    <!--                    v-model="profile.cards[i - 1].due_date"-->
    <!--                    @input="menuCard[i] = false"-->
    <!--                    no-title first-day-of-week="1"-->
    <!--                    :min="new Date(Date.now()).toISOString().substr(0, 7)"-->
    <!--                ></v-date-picker>-->
    <!--              </v-menu>-->
    <!--            </div>-->
    <!--&lt;!&ndash;            <v-file-input&ndash;&gt;-->
    <!--            &lt;!&ndash;                v-model="profile.cards[i - 1].photo"&ndash;&gt;-->
    <!--            &lt;!&ndash;                accept=".jpg, .png, .jpeg"&ndash;&gt;-->
    <!--            &lt;!&ndash;                :placeholder=" $vuetify.lang.t('$vuetify.downloadPhoto')"&ndash;&gt;-->
    <!--            &lt;!&ndash;                prepend-icon="$photo"&ndash;&gt;-->
    <!--            &lt;!&ndash;                :rules="[$rules.fileSize, $rules.required]"&ndash;&gt;-->
    <!--            &lt;!&ndash;            ></v-file-input>&ndash;&gt;-->
    <!--          </div>-->
    <!--        </template>-->
    <!--        <v-select-->
    <!--            v-if="cards.length"-->
    <!--            v-model="selectedCard"-->
    <!--            :items="cards"-->
    <!--            outlined attach-->
    <!--            :placeholder=" $vuetify.lang.t('$vuetify.addCard')"-->
    <!--            item-text="name"-->
    <!--            item-value="id"-->
    <!--            return-object-->
    <!--            @change="addCard"-->
    <!--            :menu-props="{-->
    <!--            bottom: true,-->
    <!--            offsetY: true,-->
    <!--            maxHeight: 200,-->
    <!--            contentClass: 'select-menu',-->
    <!--          }"-->
    <!--        ></v-select>-->
    <!--        <div class="sign-up__buttons btn-group">-->
    <!--          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>-->
    <!--          <button class="btn btn-primary" @click="uploadFile(files.photo)">-->
    <!--            &lt;!&ndash;            <button class="btn btn-primary" @click="uploadCards()">&ndash;&gt;-->
    <!--            {{ $vuetify.lang.t('$vuetify.forward') }}-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </v-form>-->
    <!--    </div>-->

    <SpinnerLoader :overlay="loader"></SpinnerLoader>

    <div v-if="step == 7" class="sign-up__card cards__single" key="step11">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.congratTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.congratSubtitle') }}
        </div>
      </div>
      <button class="sign-up__final btn btn-primary" @click="goLoginPage">
        {{ $vuetify.lang.t('$vuetify.gotoProfile') }}
      </button>
    </div>
  </div>
</template>

<script>
// import RatingItem from "@/components/RatingItem";
import SpinnerLoader from "@/components/SpinnerLoader";

export default {
  name: "SignUpLayout",
  components: {
    // RatingItem,
    SpinnerLoader,
  },
  props: {
    mode: String,
    id: [String, Number]
  },
//todo сделать проверку на admin и закрыть доступ к url без mode
  data: function () {
    return {
      url: "",
      admin: false,
      profile: {
        id: 0,
        name: "",
        lastname: "",
        citizenship: "",
        phone: "",
        phone_fin: null,
        position: null,
        birthdate: "",
        social_code_own: null,
        social_code_fin: "",
        tax_number: "",
        bank_account: "",
        address_own: {
          street: "",
          house: "",
          flat: "",
          entrance: "",
          index: "",
          city: "",
          country: "",
        },
        address_fin: {
          street: "",
          house: "",
          flat: "",
          entrance: "",
          index: "",
          city: "",
        },
        english: false,
        estonian: false,
        finnish: false,
        russian: false,
        other_language: null,
        auto: false,
        auto_category: [],
        tool: false,
        boots: '',
        jacket: '',
        pants: '',
        shirt: '',
        gloves: '',
        skills: {},
        photo_path: null,
        cards: [],
      },
      files: {
        photo: {
          path: null,
          file: null,
        },
        passport: {
          name: null,
          file: null,
        },
        residence: {
          name: null,
          file: null,
        },
      },
      currentGroup: {},
      groups: [],
      positions: [],
      cards: [],
      selectedCard: {},
      cardsQuantity: 0,
      uploadedCards: 0,
      valid: true,
      step: 1,
      options: {address: "yes"},
      menu1: false,
      menuCard: {
        1: false,
      },
      clothes_items: {
        clothes: [42, 44, 46, 48, 50, 52, 54, 56],
        boots: [38, 39, 40, 41, 42, 43, 44, 45, 46, 47],
        gloves: [5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12]
      },
      clothes_menu: {shirt: false, pants: false, jacket: false, boots: false, gloves: false},
      other_language_check: false,
      loader: false
    };
  },
  watch: {
    uploadedCards: function (val) {
      if (val == this.profile.cards.length) {
        this.uploadFile(this.files.photo)
      } else {
        console.log(val)
      }
    },
    profile: {
      deep: true,
      handler() {
        this.$saveForm('newProfile', this.profile)
      }
    }
  },

  created() {
    if (!(localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"))
    ) {
      this.$router.push({name: "AuthLayout"});
    } else {
      this.$axios({
        url: this.$hostname + "time-tracking/check-profile/" + this.id,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        if (response.data.data.data == true) {
          alert("Пользователь уже существует")
          this.$router.push({name: "main"});
        } else {
          this.admin = response.data.data.admin
          console.log(this.admin)
          if (this.mode == 'worker' && this.admin == true) {
            this.url = "time-tracking/profiles"
            this.loadGroups();
          } else if (this.admin == true) {
            this.$router.push({path: `/signup/${'worker'}/${this.id}`});
          } else {
            this.url = "time-tracking/register-profile"
          }
          this.loadPositions();
          // this.loadCards();
          this.profile = this.$loadForm('newProfile')
        }
      }).catch((error) => {
        this.alertMsg = this.$catch(error)
        alert(this.alertMsg);
      })
    }
  },

  methods: {
    nextStep() {
      if (!this.$refs.form.validate()) {
        this.alertError = true;
        this.alertMsg = this.$vuetify.lang.t('$vuetify.fillForm');
      } else {
        this.step += 1;
      }
    },
    loadPositions() {
      this.$axios({
        url: this.$hostname + "time-tracking/profiles/positions",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: (localStorage.getItem("auth_token") ||
            sessionStorage.getItem("auth_token")) ?
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")) : "",
        },
      })
        .then((response) => {
          this.positions = response.data.data.data;
        })
        .catch((error) => {
          this.alertMsg = "Непредвиденная ошибка";
          switch (error.response.status) {
            case 400:
              this.alertMsg = error.message;
              break;
            case 403:
              this.alertMsg = "Нет доступа";
              break;
            case 404:
              this.alertMsg = "Страница не найдена";
              break;
          }
          alert(this.alertMsg);
        });
    },
    loadCards() {
      this.$axios({
        url: this.$hostname + "time-tracking/profiles/cards",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: (localStorage.getItem("auth_token") ||
            sessionStorage.getItem("auth_token")) ?
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")) : "",
        },
      })
        .then((response) => {
          this.cards = response.data.data.data;
          let vatCard = this.cards.find(
            (el) => el.name == "Налоговая карточка" || el.name == "Verokortti"
          );
          this.profile.cards.push({
            id: 0,
            name: vatCard.name,
            card: vatCard.id,
            number: "",
            due_date: "",
            photo_path: "",
            photo: null,
          });
          this.cardsQuantity += 1;
          this.cards.splice(vatCard, 1);
        })
        .catch((error) => {
          this.alertMsg = "Непредвиденная ошибка";
          switch (error.response.status) {
            case 400:
              this.alertMsg = "Некорректные данные";
              break;
            case 403:
              this.alertMsg = "Нет доступа";
              break;
            case 404:
              this.alertMsg = "Страница не найдена";
              break;
          }
          alert(this.alertMsg);
        });
    },
    loadGroups() {
      if (this.admin) {
        this.$axios({
          url: this.$hostname + "time-tracking/groups",
          method: "GET",
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
          },
        }).then((response) => {
          this.groups = response.data.data.groups
          this.groups.forEach(group => {
            group.name = JSON.parse(group.name)
          })
        }).catch((response) => {
          this.alertMsg = this.$catch(response)
          this.alertError = true
        })
      }
    },

    addProfile() {
      if (this.$refs.form.validate()) {
        this.loader = true
        this.$axios({
          url: this.$hostname + this.url,
          method: "POST",
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
          data: {
            auth_user_id: this.id,
            name: this.profile.name,
            lastname: this.profile.lastname,
            citizenship: this.profile.citizenship,
            birthdate: this.profile.birthdate,
            social_code_own: this.profile.social_code_own,
            social_code_fin: this.profile.social_code_fin,
            address_own: JSON.stringify(this.profile.address_own),
            address_fin: JSON.stringify(this.profile.address_fin),
            phone: this.profile.phone,
            phone_fin: this.profile.phone_fin,
            bank_account: this.profile.bank_account,
            tax_number: this.profile.tax_number,
            auto: this.profile.auto,
            auto_category: JSON.stringify(this.profile.auto_category),
            tool: this.profile.tool,
            english: this.profile.english,
            estonian: this.profile.estonian,
            finnish: this.profile.finnish,
            russian: this.profile.russian,
            other_language: this.profile.other_language,
            position: this.profile.position,
            skills: this.profile.skills,
            boots: this.profile.boots,
            jacket: this.profile.jacket,
            pants: this.profile.pants,
            shirt: this.profile.shirt,
            gloves: this.profile.gloves,
            photo_path: this.files.photo.path,
            active: true,
            cards: JSON.stringify(this.profile.cards),
          },
        })
          .then((response) => {
            this.profile.id = response.data.data.id
            console.log('id' + response.data.data.id)
            console.log(this.profile.id)
            this.edit_group()
            // if (
            //     this.files.passport.file != null &&
            //     this.files.passport.file != undefined
            // ) {
            //   this.uploadFile(this.files.passport, 1, 'passport');
            // }
            // if (
            //     this.files.residence.file != null &&
            //     this.files.residence.file != undefined
            // ) {
            //   this.uploadFile(this.files.residence, 1, 'residence_view');
            // }
            this.step += 1;
            this.loader = false
            this.$clearForm('newProfile')
          })
          .catch((error) => {
            this.loader = false
            this.alertMsg = this.$catch(error)
            alert(this.alertMsg);
            this.profile.address_own = JSON.parse(this.profile.address_own)
            this.profile.address_fin = JSON.parse(this.profile.address_fin)
            this.profile.auto_category = JSON.parse(this.profile.auto_category)
          });
      }
    },
    edit_group() {
      if (this.admin) {
        this.$axios({
          url: this.$hostname + "time-tracking/user-groups",
          method: "PUT",
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
          data: {
            auth_user_id: this.id,
            group_id: this.currentGroup.id
          },
        }).then((response) => {
          this.currentGroup.name = response.data.data.data[0]
        }).catch((response) => {
          this.alertMsg = this.$catch(response)
          this.alertError = true
        })
      }
    },
    addUserDocument(file) {
      this.$axios({
        url: this.$hostname + "time-tracking/documents/worker",
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: 0,
          user_profile_id: this.profile.id,
          name: file.name,
          path: file.path,
        },
      }).then((response) => {
        return response;
      }).catch((error) => {
        this.alertMsg = this.$catch(error)
        alert(this.alertMsg);
      })
    },
    uploadFile(file, id = null, name = null) {
      if (this.$refs.form.validate() && file.file != null && file.file != undefined) {
        this.loader = true
        console.log(file.file.name);
        this.$axios({
          url: this.$hostname + "time-tracking/images/upload/users",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            file: file.file,
          },
        })
          .then((response) => {
            file.path = response.data.data;
            file.name = name != null ? name : file.file.name;
            if (id != null) {
              this.addUserDocument(file);
            } else {
              this.addProfile();
            }
          })
          .catch((error) => {
            this.alertMsg = "Ошибка загрузки файла";
            console.log(error);
            switch (error.response.status) {
              case 400:
                this.alertMsg = error.message;
                break;
              case 403:
                this.alertMsg = "Доступ запрещен";
                break;
              case 404:
                this.alertMsg = "Страница не найдена";
                break;
            }
            alert(this.alertMsg);
            if (id == null) this.addProfile();
          })
      } else {
        this.addProfile()
      }
    },

    uploadCards() {
      if (this.$refs.form.validate()) {
        this.loader = true
        this.profile.cards.forEach(card => {
          if (card.photo != null) {
            this.$axios({
              url: this.$hostname + "time-tracking/images/upload/users",
              method: "POST",
              headers: {
                "Content-Type": "multipart/form-data",
              },
              data: {
                file: card.photo,
              },
            }).then((response) => {
              card.path = response.data.data;
              this.uploadedCards++
            }).catch((error) => {
              this.alertMsg = "Ошибка загрузки файла " + card.photo.name;
              console.log(error);
              switch (error.response.status) {
                case 400:
                  this.alertMsg = error.message;
                  break;
                case 403:
                  this.alertMsg = "Доступ запрещен";
                  break;
                case 404:
                  this.alertMsg = "Страница не найдена";
                  break;
              }
              alert(this.alertMsg);
            })
          } else {
            this.uploadedCards++
          }
        })
      } else {
        this.alertMsg = this.$vuetify.lang.t("$vuetify.fillForm")
        this.alertError = true
      }
    },
    addCard() {
      this.cardsQuantity += 1;
      this.profile.cards.push({
        id: 0,
        card: this.selectedCard.id,
        name: this.selectedCard.name,
        number: "",
        due_date: "",
        photo_path: "",
        photo: null,
      });
      this.cards.splice(this.selectedCard, 1);
      this.selectedCard = -1;
    },
    deleteCard(i) {
      let item = this.profile.cards[i];
      this.cardsQuantity = this.cardsQuantity - 1;
      this.cards.push({id: item.card, name: item.name});
      this.profile.cards.splice(i, 1);
    },

    deleteUser() {
      this.$axios
        .delete(this.$hostname + "time-tracking/register-user", {
          data: {
            id: this.id,
          },
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.alertMsg = "Пользователь удален";
          if (this.mode != 'worker') {
            localStorage.clear()
            sessionStorage.clear()
          } else {
            this.$router.push({name: 'workers'})
          }
        })
        .catch((response) => {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        });
    },
    goLoginPage() {
      this.$router.push({name: "AuthLayout"});
    },
  }
};
</script>

<style scoped></style>
