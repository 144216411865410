<template>
  <tr class="man-wrap" :class="{ rows__single: type == 'rows' }">
    <div class="cards__single" v-if="type == 'cards'">
      <router-link
          :to="'/garage/' + id"
          class="link-not-like photo photo-human"
      >
        <img v-if="photo" :src="$media_url + photo" alt="photo"/>
      </router-link>
      <div class="cards__main-info">
        <h2>
          <router-link class="link-not-like" :to="'/garage/' + id"
          >{{ options.array[0].value }}
          </router-link>
        </h2>
        <span class="main-info__description">{{ options.array[1].value }} </span>
      </div>
      <div class="cards__buttons">
        <button v-if="$parent.$parent.edit.indexOf('Гараж') != -1" class="btn-icon btn-action"
                @click="$emit('edit', id)">
          <picture>
            <edit-icon/>
          </picture>
        </button>
        <button v-if="$parent.$parent.edit.indexOf('Гараж') != -1" class="btn-icon btn-action"
                @click="confirm_archive_modal=true">
          <picture>
            <archive-icon/>
          </picture>
        </button>
        <button v-if="$parent.$parent.edit.indexOf('Гараж') != -1" class="btn-icon btn-action"
                @click="confirm_open(id)">
          <picture>
            <delete-icon/>
          </picture>
        </button>
      </div>
      <ul class="cards__info">
        <li v-for="(option, idx) in options.array.slice(2, 7)" :key="idx">
          <span v-html="option.icon"></span>
          <span v-html="option.value"></span>
        </li>
      </ul>
    </div>
    <template v-if="type == 'rows'">
      <!-- <td class="rows__dot-menu">
        <input
          type="checkbox"
          v-model="the_checked"
          @change="$emit('change', the_checked)"
        />
      </td> -->
      <template v-for="(header) in headers">
        <template v-if="$parent.showHeaders[header.key]">
          <td v-if="header.key == 'name'" :key="header.key">
            <router-link :to="'/garage/' + id" class="link-not-like flex">
              <div class="avatar avatar--client">
                <img v-if="photo" :src="$media_url + photo" alt=""/>
              </div>
              {{ options[header.key] }}
            </router-link>
          </td>
          <!--          v-for="(option, idx) in options.slice(1, 5)"-->
          <td v-else :key="header.key" v-html="options[header.key] "></td>
        </template>
      </template>
      <td class="rows__dot-menu">
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <svg
                  v-bind="attrs"
                  v-on="on"
                  width="20"
                  height="5"
                  viewBox="0 0 20 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2" cy="2.5" r="2" fill="#2C1521"/>
                <circle cx="10" cy="2.5" r="2" fill="#2C1521"/>
                <circle cx="18" cy="2.5" r="2" fill="#2C1521"/>
              </svg>
            </v-btn>
          </template>
          <div class="rows__dot-menu-content">
            <div v-if="$parent.$parent.edit.indexOf('Гараж') != -1" class="btn-wrap" @click="$emit('edit', id)">
              {{ $vuetify.lang.t('$vuetify.edit') }}
              <button class="btn-icon btn-action">
                <picture>
                  <edit-icon/>
                </picture>
              </button>
            </div>
            <!--            <div v-if="$parent.$parent.edit.indexOf('Гараж') != -1" class="btn-wrap"-->
            <!--                 @click="$emit('archive', id, !active)">-->
            <div v-if="$parent.$parent.edit.indexOf('Гараж') != -1" class="btn-wrap"
                 @click="confirm_archive_modal=true">
              <span v-if="active">{{ $vuetify.lang.t('$vuetify.archive') }}</span>
              <span v-else>{{ $vuetify.lang.t('$vuetify.deleteArchive') }}</span>
              <button class="btn-icon btn-action">
                <picture>
                  <archive-icon/>
                </picture>
              </button>
            </div>
            <div v-if="$parent.$parent.edit.indexOf('Гараж') != -1" class="btn-wrap"
                 @click="confirm_open(id)">
              {{ $vuetify.lang.t('$vuetify.delete') }}
              <button class="btn-icon btn-action">
                <picture>
                  <delete-icon/>
                </picture>
              </button>
            </div>
          </div>
        </v-menu>
      </td>
    </template>
    <ConfirmModal v-model="confirm_modal" @confirm="tmp_delete">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
    <ConfirmArchiveModal v-model="confirm_archive_modal" @confirm="tmp_archive" :active="active">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmArchiveModal>

  </tr>
</template>

<script>
import EditIcon from "@/components/icons/EditIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import ConfirmModal from "@/components/confirmModal";
import ArchiveIcon from "@/components/icons/ArchiveIcon";
import ConfirmArchiveModal from "@/components/ConfirmArchiveModal";

export default {
  name: "GarageCard",
  components: {ConfirmArchiveModal, ArchiveIcon, DeleteIcon, EditIcon, ConfirmModal,},

  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: Boolean,
    active: Boolean,
    type: String,
    options: Object,
    headers: Array,
    photo: String,
    id: Number,
  },

  data: function () {
    return {
      the_checked: this.checked,
      tmp: false,
      confirm_modal: false,
      confirm_archive_modal: false,
      tmp_to_delete: "",
      alertMsg: "",
      alertError: false,
    };
  },

  methods: {
    confirm_open(id) {
      this.confirm_modal = true;
      this.tmp_to_delete = id;
    },

    confirm_close() {
      this.confirm_modal = false;
      this.tmp_to_delete = "";
    },

    tmp_delete() {
      console.log('delete')
      this.$axios
          .delete(this.$hostname + "time-tracking/garage", {
            data: {
              id: this.id,
            },
            headers: {
              Authorization:
                  "Token " +
                  (localStorage.getItem("auth_token") ||
                      sessionStorage.getItem("auth_token")),
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.confirm_modal = false;
            this.$destroy();

            // remove the element from the DOM
            this.$el.parentNode.removeChild(this.$el);
          })
          .catch((response) => {
            this.alertMsg = this.$catch(response);
            this.alertError = true;
          });
    },
    tmp_archive() {
      console.log('archive')
      this.$axios({
        url: this.$hostname + "time-tracking/garage",
        method: "PUT",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.id,
          active: !this.active
        },
      }).then(() => {
        this.confirm_archive_modal = false
        this.$emit('archive')
      }).catch((response) => {
        this.loader = false
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
  },
}
</script>

<style scoped>

</style>