<template>
  <div class="page-wrap">
    <div class="page-head">
      <h1 class="h1 dTm-none">{{ $vuetify.lang.t('$vuetify.menu.todo') }}</h1>
      <button v-if="$parent.admin" class="btn-secondary btn" @click="action='add'; todoCreateOpen();">
        + {{ $vuetify.lang.t('$vuetify.add') }}
      </button>
    </div>
    <div class="page-head">
      <div class="page__sorts">
        <v-menu ref="date" v-model="menu.dates"
                :close-on-content-click="false" transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field class="search-field" :value="range.join(' ~ ')"
                          @keypress.enter="loadData()" prepend-inner-icon="$calendar"
                          :placeholder="$vuetify.lang.t('$vuetify.date')" outlined
                          hide-details v-bind="attrs" v-on="on" readonly>
            </v-text-field>
          </template>
          <v-date-picker v-model="range" no-title first-day-of-week="1" show-week
                         range @input="range.length == 2  ? menu.dates = false : menu.dates = true"
          ></v-date-picker>
          <!--      @input="menus.range = false"  :allowed-dates="allowedDates"  -->
        </v-menu>
      </div>
    </div>
    <div class="accordions">
      <div class="cards__single" v-for="(item, date) in todo" :key="date">
        <div class="accordion__title">
          <div class="accordion__title-text h2 flex">
            {{ new Date(date).toLocaleDateString('ru-Ru') }}
            <div class="useful-item__icon cp" v-if="$parent.admin"
                 @click="action='edit'; todoEditOpen(item, date);">
              <edit-icon/>
            </div>
            <div class="useful-item__icon cp" v-if="$parent.admin"
                 @click="deletedTodo={date: date}; confirmModal = true">
              <delete-icon/>
            </div>
          </div>
        </div>
        <div v-for="(data, obj) in item" :key="obj">
          <div class="accordion__content">
            <div class="bolder todo__object">
              <div>{{ obj }}</div>
              <span class="object__divider todo__divider">-</span>
              <a target="_blank"
                 :href="'https://www.google.com/maps/search/?api=1&query='+data.object.street + '+' + data.object.house + '+' + data.object.city">
                {{ data.object.street }} {{ data.object.house }}{{
                  data.object.entrance ? data.object.entrance +
                    ' ' : ''
                }}
                {{ data.object.flat ? data.object.flat + ', ' : ', ' }}{{ data.object.index }}
                {{ data.object.city }}, {{ data.object.country }}
              </a>

              <div class="useful-item__icon cp" v-if="$parent.admin"
                   @click="action='edit'; todoEditObjectOpen(data, date);">
                <edit-icon/>
              </div>
              <div class="useful-item__icon cp" v-if="$parent.admin"
                   @click="deletedTodo={date: date, object: data.object.id}; confirmModal = true">
                <delete-icon/>
              </div>
            </div>
            <template v-for="worker in data.data">
              <div class="todo__worker" :key="worker.id">
                <div>{{ worker.time.substring(0, 5) }} - <span class="bolder">{{
                    worker.user_profile.name
                  }} {{ worker.user_profile.lastname }}</span></div>
                <span class="todo__divider">-</span>
                <div v-html="worker.comment"></div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <v-dialog v-model="todoCreateModal" :retain-focus="false">
        <div class="modal modal-height modal-big">
          <div class="h1">
            <span v-if="action == 'add'">{{ $vuetify.lang.t('$vuetify.add') }}</span><span
            v-if="action == 'edit'">{{ $vuetify.lang.t('$vuetify.edit') }}</span>
          </div>
          <v-form ref="form" @submit.prevent="">
            <div>
              <v-menu v-model="menu.date" :close-on-content-click="false" transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="new Date(newTodo.date).toLocaleDateString('ru-Ru')"
                                :label="$vuetify.lang.t('$vuetify.date')" append-icon="$calendar" v-bind="attrs"
                                v-on="on"
                                outlined readonly :rules="[$rules.required]">
                  </v-text-field>
                </template>
                <v-date-picker v-model="newTodo.date" no-title @input="menu.date = false"
                               :allowed-dates="allowedDates" first-day-of-week="1" show-week></v-date-picker>
              </v-menu>
              <div v-for="(object, idx) in newTodo.objects" :key="object.id">
                <div class="form__row justify-between">
                  <div class="bolder"> {{ object.name }}</div>
                  <button class="btn-icon" @click="removeObject(idx)">
                    <delete-icon/>
                  </button>
                </div>
                <div v-for="(worker, i) in newTodo.objects[idx].workers" :key="worker.id">
                  <div class="form__row justify-between">
                    {{ newTodo.objects[idx].workers[i].name }} {{ newTodo.objects[idx].workers[i].lastname }}
                    <button class="btn-icon" @click="removeWorker(idx, i)">
                      <delete-icon/>
                    </button>
                  </div>
                  <editor v-model="newTodo.objects[idx].workers[i].comment"
                          api-key="660vq0feuhjyub7s7o01nh9an48e4eq55ucbneldw55cr22l"
                          :init="editorInit" :id="'editor'+'-'+idx+i.toString()"
                          @change="$saveForm(action+'newTodo', newTodo)"/>
                  <v-menu :ref="'time'+'-'+idx+i.toString()" v-model="menu['time'+'-'+idx+i.toString()]"
                          :close-on-content-click="false"
                          :nudge-right="40" :return-value.sync="newTodo.objects[idx].workers[i].time"
                          transition="scale-transition"
                          offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="newTodo.objects[idx].workers[i].time"
                                    :label="$vuetify.lang.t('$vuetify.accounting.card.time_start')" readonly
                                    v-bind="attrs"
                                    v-on="on" outlined :rules="[$rules.required]"
                                    @change="$saveForm(action+'newTodo', newTodo)"></v-text-field>
                    </template>
                    <v-time-picker v-if="menu['time'+'-'+idx+i.toString()]"
                                   v-model="newTodo.objects[idx].workers[i].time"
                                   full-width
                                   format="24hr"
                                   :allowed-minutes="allowedMinutes"
                                   @click:minute="$refs['time'+'-'+idx+i.toString()][0].save(newTodo.objects[idx].workers[i].time)"></v-time-picker>
                  </v-menu>

                </div>
                <v-autocomplete v-model="selectedWorker" :items="workers" outlined
                                :placeholder="'+ ' + $vuetify.lang.t('$vuetify.addEmployee')" item-text="name"
                                item-value="id"
                                return-object attach
                                @change="addWorker(idx)" :menu-props="{
                              bottom: true,
                              offsetY: true,
                              maxHeight: 400,
                              contentClass: 'select-menu',
                            }">
                  <template v-slot:item="{ item }">
                    {{ item.name }} {{ item.lastname }}
                  </template>
                </v-autocomplete>
              </div>
              <v-autocomplete v-model="selectedObject" :items="objects" outlined
                              :placeholder="'+ ' + $vuetify.lang.t('$vuetify.addObject')" item-text="name"
                              item-value="id"
                              return-object attach
                              @change="addObject" :menu-props="{
                  bottom: true,
                  offsetY: true,
                  maxHeight: 400,
                  contentClass: 'select-menu',
                }"></v-autocomplete>
              <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
            </div>
            <div class="sign-up__buttons btn-group">
              <button class="btn btn-secondary" @click.stop="todoCreateClose">
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </button>
              <button class="btn btn-primary" @click.stop="addTodo">
                {{ $vuetify.lang.t('$vuetify.save') }}
              </button>
            </div>
          </v-form>
        </div>
      </v-dialog>

      <ConfirmModal v-model="confirmModal" @confirm="deleteTodo">
        <span v-if="alertError">{{ alertMsg }}</span>
      </ConfirmModal>

      <SpinnerLoader :overlay="loader"></SpinnerLoader>
    </div>
  </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader";
import ConfirmModal from "@/components/confirmModal";
import DeleteIcon from "@/components/icons/DeleteIcon";
import EditIcon from "@/components/icons/EditIcon";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "TodoList",
  components: {EditIcon, DeleteIcon, ConfirmModal, SpinnerLoader, editor: Editor},
  data: function () {
    return {
      range: [],

      todo: [],
      objects: [],
      workers: [],

      action: '',
      newTodo: {
        date: new Date().toISOString().substring(0, 10),
        objects: [],
      },
      deleted: [],
      selectedObject: null,
      selectedWorker: null,
      todoCreateModal: false,
      confirmModal: false,
      deletedTodo: null,
      menu: {
        date: false,
        time: []
      },
      editorInit: {
        menubar: false,
        plugins: [
          "paste advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "bold italic underline backcolor link smiles",
        contextmenu: false,
        setup: (editor) => {
          editor.ui.registry.addMenuButton('smiles', {
            text: '😀',
            fetch: (callback) => {
              const items = [
                {
                  type: 'menuitem',
                  text: '😀',
                  onAction: () => editor.insertContent('😀')
                },
                {
                  type: 'menuitem',
                  text: '😉',
                  onAction: () => editor.insertContent('😉')
                },
                {
                  type: 'menuitem',
                  text: '😂',
                  onAction: () => editor.insertContent('😂')
                },
                {
                  type: 'menuitem',
                  text: '😡',
                  onAction: () => editor.insertContent('😡')
                },
                {
                  type: 'menuitem',
                  text: '😱',
                  onAction: () => editor.insertContent('😱')
                },
                {
                  type: 'menuitem',
                  text: '🤨',
                  onAction: () => editor.insertContent('🤨')
                },
                {
                  type: 'menuitem',
                  text: '😍',
                  onAction: () => editor.insertContent('😍')
                },
                {
                  type: 'menuitem',
                  text: '👌',
                  onAction: () => editor.insertContent('👌')
                },
                {
                  type: 'menuitem',
                  text: '👍',
                  onAction: () => editor.insertContent('👍')
                },
                {
                  type: 'menuitem',
                  text: '👎',
                  onAction: () => editor.insertContent('👎')
                }];
              callback(items);
            }
          })
        },
      },
      alertError: false,
      alertMsg: "",
      loader: false,
      form_loader: false,
    }
  },

  watch: {
    range: function () {
      if (this.range.length == 2)
        this.loadData()
    },
    'newTodo.objects': {
      deep: true,
      handler() {
        this.$saveForm(this.action + 'newTodo', this.newTodo)
      },
    },
  },

  created: function () {
    this.loadData()
  },

  methods: {
    allowedDates: val => val >= new Date().toISOString(),
    allowedMinutes: v => v % 5 == 0,

    loadData: function () {
      this.loader = true
      this.$axios({
        url: this.$hostname + "time-tracking/todo",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
        params: {
          range: JSON.stringify(this.range),
        },
      }).then((response) => {
        if (response.data.data.data) {
          this.todo = response.data.data.data;
        }
        if (this.$parent.admin) {
          this.loadObjects()
          this.loadWorkers()
        }
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      }).then(() => {
        this.loader = false
      })
    },
    loadObjects() {
      this.$axios({
        url: this.$hostname + "time-tracking/accounting/objects",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        }
      }).then((response) => {
        this.objects = response.data.data.data
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    loadWorkers() {
      this.$axios({
        url: this.$hostname + "time-tracking/profiles/active",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      })
        .then((response) => {
          this.workers = response.data.data.data;
        })
        .catch((response) => {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        });
    },

    addTodo() {
      if (this.$refs.form.validate()) {
        let items = []
        this.newTodo.objects.forEach(o => {
          o.workers.forEach(w => {
            items.push({
              date: this.newTodo.date,
              object: o.id,
              user_profile: w.id,
              time: w.time,
              comment: w.comment,
              id: w.todo ?? 0
            })
          })
        })
        if (this.action == 'edit') {
          this.editTodo(items)
        } else {
          this.$axios({
            url: this.$hostname + "time-tracking/todo",
            method: "POST",
            headers: {
              Authorization:
                "Token " +
                (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
              "Content-Type": "multipart/form-data",
            },
            data: {items: JSON.stringify(items)},
          }).then(() => {
            this.loadData()
            this.todoCreateClose()
          }).catch((response) => {
            this.alertMsg = this.$catch(response)
            this.alertError = true
          })
        }
      } else {
        this.alertMsg = this.$vuetify.lang.t('$vuetify.fillForm')
        this.alertError = true
      }
    },
    editTodo(items) {
      this.$axios({
        url: this.$hostname + "time-tracking/todo",
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          items: JSON.stringify(items),
          deleted: JSON.stringify(this.deleted)
        },
      }).then(() => {
        this.loadData()
        this.todoCreateClose()
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    deleteTodo() {
      this.$axios({
        url: this.$hostname + "time-tracking/todo",
        method: "DELETE",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {date: this.deletedTodo.date, object: this.deletedTodo.object},
      }).then(() => {
        this.loadData()
        this.confirmModal = false
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    addObject() {
      if (!this.newTodo.objects)
        this.newTodo.objects = []
      if (this.newTodo.objects.indexOf(this.selectedObject) == -1)
        this.newTodo.objects.push(this.selectedObject)
      this.selectedObject = {}
    },
    removeObject(idx) {
      if (this.action == 'edit') {
        if (this.newTodo.objects[idx].workers != undefined && this.newTodo.objects[idx].workers.length != 0) {
          this.newTodo.objects[idx].workers.forEach(w => {
            if (w.todo != 0)
              this.deleted.push(w.todo)
          })
        }
      }
      this.newTodo.objects.splice(idx, 1)
    },
    addWorker(idx) {
      if (!this.newTodo.objects[idx].workers) {
        this.newTodo.objects[idx].workers = [structuredClone(this.selectedWorker)]
      } else if (this.newTodo.objects[idx].workers.indexOf(this.selectedWorker) == -1) {
        this.newTodo.objects[idx].workers.push(structuredClone(this.selectedWorker))
      }
      this.selectedWorker = {}
    },
    removeWorker(idx, i) {
      if (this.newTodo.objects[idx].workers[i].todo != 0)
        this.deleted.push(this.newTodo.objects[idx].workers[i].todo)
      this.newTodo.objects[idx].workers.splice(i, 1)
      this.$forceUpdate()
    },
    todoCreateOpen() {
      this.todoCreateModal = true
      let form = this.$loadForm(this.action + 'newTodo')
      console.log('length ' + form.objects.length)
      if (form.objects.length)
        this.newTodo = form
      else {
        this.newTodo = {
          date: new Date().toISOString().substring(0, 10),
          objects: [],
        }
      }
    },
    todoEditOpen(items, date) {
      let form = this.$loadForm(this.action + 'newTodo')
      console.log('length ' + form.objects.length)
      if (form.objects.length)
        this.newTodo = form
      else {
        this.newTodo.date = date
        this.deleted = []
        let objects = []
        for (let obj in items) {
          objects.push(items[obj].object)
        }
        this.newTodo.objects = objects
        this.newTodo.objects.forEach(obj => {
          obj.workers = []
          items[obj.name].data.forEach(line => {
            obj.workers.push(line.user_profile)
            obj.workers[obj.workers.length - 1].comment = line.comment
            obj.workers[obj.workers.length - 1].time = line.time
            obj.workers[obj.workers.length - 1].todo = line.id
          })
        })
      }
      this.todoCreateModal = true
    },
    todoEditObjectOpen(data, date) {
      this.newTodo.date = date
      this.deleted = []
      this.newTodo.objects = [data.object]
      this.newTodo.objects[0].workers = []
      data.data.forEach(line => {
        console.log(line.user_profile)
        this.newTodo.objects[0].workers.push(line.user_profile)
        this.newTodo.objects[0].workers[this.newTodo.objects[0].workers.length - 1].comment = line.comment
        this.newTodo.objects[0].workers[this.newTodo.objects[0].workers.length - 1].time = line.time
        this.newTodo.objects[0].workers[this.newTodo.objects[0].workers.length - 1].todo = line.id
      })
      this.todoCreateModal = true
    },
    todoCreateClose() {
      this.$clearForm(this.action + 'newTodo')
      this.newTodo = {
        date: new Date().toISOString().substring(0, 10),
        objects: [],
      }
      this.todoCreateModal = false
      this.alertError = false
    },
  }
}
</script>

<style scoped>

</style>