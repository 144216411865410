<template>
  <div class="auth">
    <div class="auth__img">
      <img src="../../assets/img/bg.svg"/>
    </div>
    <div class="auth-form">
      <div class="cards__single">
        <h1 class="auth-form__title">{{ $vuetify.lang.t('$vuetify.account') }}</h1>
        <div class="auth-form__tabs">
          <div
            class="tab"
            @click="tab = 'login'"
            :class="{ active: tab === 'login' }"
          >
            <h2 class="tab__header">{{ $vuetify.lang.t('$vuetify.auth') }}</h2>
          </div>
          <div
            class="tab"
            @click="tab = 'register'"
            :class="{ active: tab === 'register' }"
          >
            <h2 class="tab__header">{{ $vuetify.lang.t('$vuetify.registration') }}</h2>
          </div>
        </div>
        <div class="auth-form__content">
          <div v-if="tab == 'register'" class="mb-5">
            <button class="btn btn-secondary"
                    @click="$router.push({'name': 'SignUpTenantLayout'})">
              {{ $vuetify.lang.t('$vuetify.rentPlatform') }}
            </button>
          </div>
          <v-form ref="auth" @submit.prevent="">
            <v-text-field
              v-model="user.login"
              label="E-mail"
              outlined
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-text-field
              v-model="user.password"
              :label="$vuetify.lang.t('$vuetify.password')"
              outlined
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
            ></v-text-field>
            <a class="link auth-form__forget" href="/password/reset"
               v-if="tab == 'login'">{{ $vuetify.lang.t('$vuetify.forget') }}</a>
            <div v-if="tab == 'login'" class="auth-form__login">
              <button class="btn btn-primary" @click="setLogin">{{ $vuetify.lang.t('$vuetify.enter') }}</button>
              <v-checkbox class="auth-form__checkbox" v-model="checkbox"
                          :label="$vuetify.lang.t('$vuetify.stayIn')"></v-checkbox>
            </div>
            <div v-if="tab == 'register'" class="auth-form__register">
              <button class="btn btn-primary" @click="addUser">
                {{ $vuetify.lang.t('$vuetify.create') }}
              </button>
            </div>
          </v-form>
          <div v-if="alertError" class="error--text">
            {{ alertMsg }}
          </div>
        </div>
        <!--        <div class="auth-form__qua">-->
        <!--          <span class="link">Есть вопросы?</span>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",

  data: function () {
    return {
      tab: "login",
      user: {
        id: 0,
        login: "",
        password: "",
      },
      checkbox: true,
      show: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Некорректный E-mail",
      },
      alertError: false,
      alertMsg: "",
    };
  },

  created() {
    if (localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token")) {
      this.$router.push({name: "main"});
    }
  },

  methods: {
    setLogin() {
      if (this.$refs.auth.validate()) {
        this.$axios({
          url: this.$hostname + "auth/token/login",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            email: this.user.login.toLowerCase().replace(" ", ""),
            password: this.user.password,
          },
        }).then((response) => {
          if (this.checkbox)
            localStorage.setItem(
              "auth_token",
              response.data.data.attributes.auth_token
            );
          else
            sessionStorage.setItem(
              "auth_token",
              response.data.data.attributes.auth_token
            );
          if (this.tab == "login")
            this.$router.push({name: "main"});
          else
            this.$router.push({name: "SignUpLayout", params: {mode: 'sign-up', id: this.user.id},});
        }).catch((error) => {
          this.alertMsg = this.$vuetify.lang.t('$vuetify.' + this.$catch(error)[0].code)
          this.alertError = true;
        });
      }
    },
    addUser() {
      if (this.$refs.auth.validate()) {
        this.$axios({
          url: this.$hostname + "time-tracking/register-user",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            email: this.user.login.toLowerCase().replace(" ", ""),
            password: this.user.password,
          },
        }).then((response) => {
          this.user.id = response.data.data.id;
          this.setLogin()
        }).catch((error) => {
          this.alertMsg = this.$catch(error)
          this.alertError = true;
        });
      }
    },
  },
};
</script>

<style scoped lang="sass"></style>
