<template>

  <div class="page-wrap">
    <div class="page-head">
      <div class="h1">
        {{ $vuetify.lang.t('$vuetify.menu.faq') }}
      </div>


      <div class="page-head__buttons">
        <button v-if="$parent.admin" class="btn-secondary btn" @click="action='add'; faqCreateOpen()">
          + {{ $vuetify.lang.t('$vuetify.add') }}
        </button>
      </div>
    </div>

    <div class="faq accordions">
      <div class="accordion cards__single" v-for="(accordion, idx) in faq" :key="accordion.id">
        <div class="accordion__title" @click="switchAccordion(idx)">
          <div class="accordion__title-text h2 flex">
            {{ accordion.title[$vuetify.lang.current] }}
            <div class="useful-item__icon cp" v-if="$parent.admin"
                 @click.stop="newFaq = accordion; faqCreateOpen()">
              <edit-icon/>
            </div>
            <div class="useful-item__icon cp" v-if="$parent.admin"
                 @click.stop="faqToDelete = accordion.id; confirmFaqModal = true">
              <delete-icon/>
            </div>
          </div>
          <div class="accordion__title-icon">
            <svg v-if="accordions[idx].active" width="21" height="11" viewBox="0 0 21 11" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 10L10.5 1L19.5 10" stroke="#88B1E1" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            <svg v-if="!accordions[idx].active" width="21" height="11" viewBox="0 0 21 11" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 1L10.5 10L19.5 1" stroke="#88B1E1" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="accordion__content" :class="{ 'accordion__content--close': !accordions[idx].active }"
             :style="'height: ' + (accordions[idx].active ? accordions[idx].height + 'px' : '0') + ';'">
          <div class="contacts-accordion__text" :ref="'accordion-' + idx">
            {{ accordion.text[$vuetify.lang.current] }}
          </div>
        </div>
      </div>
    </div>

    <div class="faq-no-question">
      <span class="faq__text-us">{{ $vuetify.lang.t("$vuetify.notFoundAnswer") }}</span>
      <div class="faq__links">
        <a class="link flex">
          <svg width="20" height="17" viewBox="0 0 20 17" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6004 0.799805C17.396 0.799805 18.1591 1.11588 18.7217 1.67848C19.2843 2.24109 19.6004 3.00416 19.6004 3.7998V13.3998C19.6004 14.1955 19.2843 14.9585 18.7217 15.5211C18.1591 16.0837 17.396 16.3998 16.6004 16.3998H3.40039C2.60474 16.3998 1.84168 16.0837 1.27907 15.5211C0.716461 14.9585 0.400391 14.1955 0.400391 13.3998V3.7998C0.400391 3.00416 0.716461 2.24109 1.27907 1.67848C1.84168 1.11588 2.60474 0.799805 3.40039 0.799805H16.6004ZM18.4004 5.553L10.3052 10.317C10.2287 10.3619 10.1431 10.3893 10.0547 10.3972C9.96631 10.4051 9.87727 10.3933 9.79399 10.3626L9.69559 10.317L1.60039 5.5554V13.3998C1.60039 13.8772 1.79003 14.335 2.1276 14.6726C2.46516 15.0102 2.923 15.1998 3.40039 15.1998H16.6004C17.0778 15.1998 17.5356 15.0102 17.8732 14.6726C18.2107 14.335 18.4004 13.8772 18.4004 13.3998V5.553ZM16.6004 1.9998H3.40039C2.923 1.9998 2.46516 2.18945 2.1276 2.52701C1.79003 2.86458 1.60039 3.32241 1.60039 3.7998V4.1622L10.0004 9.10381L18.4004 4.15981V3.7998C18.4004 3.32241 18.2107 2.86458 17.8732 2.52701C17.5356 2.18945 17.0778 1.9998 16.6004 1.9998Z"
                fill="#2C1521"/>
          </svg>
          support@work24.fi</a>
      </div>
    </div>


    <v-dialog v-model="faqCreateModal">
      <div class="modal modal-big">
        <div class="h1">
          <span v-if="action == 'add'"> {{ $vuetify.lang.t('$vuetify.add') }}</span>
          <span v-if="action == 'edit'"> {{ $vuetify.lang.t('$vuetify.edit') }}</span>
        </div>
        <v-form ref="form" @submit.prevent="">
          <div class="h2">{{ $vuetify.lang.t('$vuetify.title') }}</div>
          <template v-for="lang in Object.keys($vuetify.lang.locales)">
            <v-text-field v-if="!['en', 'ru'].includes(lang)" :key="'title' + lang"
                          v-model="newFaq.title[lang]"
                          :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined
                          :rules="[$rules.required]"></v-text-field>
          </template>
          <div class="h2">{{ $vuetify.lang.t('$vuetify.text') }}</div>
          <template v-for="lang in Object.keys($vuetify.lang.locales)">
            <v-textarea v-if="!['en', 'ru'].includes(lang)" :key="'text' + lang"
                        v-model="newFaq.text[lang]"
                        :label="$vuetify.lang.t('$vuetify.settings.languages.'+lang)" outlined
                        :rules="[$rules.required]"></v-textarea>
          </template>
          <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
          <div class="sign-up__buttons btn-group">
            <div class="btn btn-secondary" @click="faqCreateClose">
              {{ $vuetify.lang.t('$vuetify.cancel') }}
            </div>
            <button class="btn btn-primary" @click="addFaq">
              <span>{{ $vuetify.lang.t('$vuetify.save') }}</span>
            </button>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <ConfirmModal v-model="confirmFaqModal" @confirm="deleteFaq">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
  </div>
</template>

<script>
import ConfirmModal from "@/components/confirmModal";
import EditIcon from "@/components/icons/EditIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";

// class Accordion {
//   constructor(title, text, open = false, maxH = 150) {
//     this.title = title
//     this.text = text
//     this.open = open
//     this.maxH = maxH
//   }
// }

export default {
  name: 'faq-view',
  components: {DeleteIcon, EditIcon, ConfirmModal},
  data: function () {
    return {
      accordions: [
        // new Accordion('Как называется раздел, где находятся сотрудники компаний-клиентов?',
        //     'Здесь будет написан ответ на заданный вопрос выше. Одновременно может высвечиваться только 1 открытый вопрос, остальные автоматически закрываются при открытии нового вопроса. Здесь будет написан ответ на заданный вопрос выше. Одновременно может высвечиваться только 1 открытый вопрос, остальные автоматически закрываются при открытии нового вопроса. Здесь будет написан ответ на заданный вопрос выше. Одновременно может высвечиваться только 1 открытый вопрос, остальные автоматически закрываются при открытии нового вопроса. ',
        //     true,
        //     300
        // ),
        //
        // new Accordion('Как называется раздел, где находятся сотрудники компаний-клиентов?',
        //     'Здесь будет написан ответ на заданный вопрос выше. Одновременно может высвечиваться только 1 открытый вопрос, остальные автоматически закрываются при открытии нового вопроса. '
        // ),
        //
        // new Accordion('Как называется раздел, где находятся сотрудники компаний-клиентов?',
        //     'Здесь будет написан ответ на заданный вопрос выше. Одновременно может высвечиваться только 1 открытый вопрос, остальные автоматически закрываются при открытии нового вопроса. '
        // )
      ],
      faq: [],

      newFaq: {
        title: {},
        text: {}
      },
      faqCreateModal: false,
      action: "",
      faqToDelete: null,
      confirmFaqModal: false,

      alertMsg: "",
      alertError: "",
    }
  },
  created() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.$axios({
        url: this.$hostname + "time-tracking/faq",
        method: "GET",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        this.faq = response.data.data.data
        // this.faq.forEach(item => {
        //   item.active = false
        //   item.height = 0
        //   console.log(item)
        // })
        for (let i = 0; i < this.faq.length; i++) {
          this.accordions.push({active: false, height: 0})
        }
        console.log(this.faq)
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },

    addFaq() {
      if (this.$refs.form.validate()) {
        if (this.newFaq.id) {
          this.putFaq()
        } else {
          this.$axios({
            url: this.$hostname + "time-tracking/faq",
            method: "POST",
            headers: {
              Authorization:
                  "Token " +
                  (localStorage.getItem("auth_token") ||
                      sessionStorage.getItem("auth_token")),
              "Content-Type": "multipart/form-data",
            },
            data: {
              title: JSON.stringify(this.newFaq.title),
              text: JSON.stringify(this.newFaq.text)
            },
          }).then(() => {
            this.loadData()
            this.faqCreateClose()
          }).catch((response) => {
            this.newFaq.title = JSON.parse(this.newFaq.title)
            this.newFaq.text = JSON.parse(this.newFaq.text)
            this.alertMsg = this.$catch(response)
            this.alertError = true
          })
        }
      } else {
        this.alertMsg = this.$vuetify.lang.t('$vuetify.fillForm')
        this.alertError = true
      }
    },
    putFaq() {
      this.$axios({
        url: this.$hostname + "time-tracking/faq",
        method: "PUT",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: this.newFaq.id,
          title: JSON.stringify(this.newFaq.title),
          text: JSON.stringify(this.newFaq.text)
        },
      }).then(() => {
        this.loadData()
        this.faqCreateClose()
      }).catch((response) => {
        this.newFaq.title = JSON.parse(this.newFaq.title)
        this.newFaq.text = JSON.parse(this.newFaq.text)
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    deleteFaq() {
      this.$axios({
        url: this.$hostname + "time-tracking/faq",
        method: "DELETE",
        headers: {
          Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                  sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {id: this.faqToDelete},
      }).then(() => {
        this.loadData()
        this.confirmFaqModal = false
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    faqCreateOpen() {
      this.faqCreateModal = true
    },
    faqCreateClose() {
      this.faqCreateModal = false
      this.newFaq = {
        title: {},
        text: {},
      }
    },

    switchAccordion(i) {
      console.log(i)
      console.log(this.$refs["accordion-" + i]);
      let a = this.$refs["accordion-" + i][0].clientHeight;
      console.log(a);
      this.accordions[i].height = a;
      this.accordions[i].active = !this.accordions[i].active;
    },
  }

}
</script>
