<template>
  <v-app class="work24">
    <main>
      <router-view></router-view>
    </main>
  </v-app>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import config from "./assets/config";

Vue.prototype.$axios = axios;
Vue.prototype.$hostname = config.url;
Vue.prototype.$media_url = config.media_url;
Vue.prototype.$tech = config.tech;
Vue.prototype.$type_objects = window.width < 600 ? "cards" : "rows";
Vue.prototype.$type_workers = window.width < 600 ? "cards" : "rows";
Vue.prototype.$type_contacts = window.width < 600 ? "cards" : "rows";
Vue.prototype.$type_clients = window.width < 600 ? "cards" : "rows";
Vue.prototype.$type_garage = window.width < 600 ? "cards" : "rows";
Vue.prototype.$theme_mode = 'light'
Vue.prototype.$rules = {
  required: (value) => !!value || "Required",
  requiredNumber: (value) => (String(value).length > 0 || value === 0) || "Required",
  text: (value) => /[^\d\s]/.test(value) || "Letters only",
  positiveNumbers: (v) => /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/.test(v) || "Positive numbers only",
  nullPositiveNumbers: (v) => /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/.test(v) || v == "" || v == 0 || v == null || "Positive or zero",
  email: (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Incorrect E-mail",
  fileSize: (value) => !value || value.size < 10000000 || 'File size should be less than 10 MB!' + value.size,
  multipleFileSize: (v) => !v || !v.some(file => file.size > 10000000) || 'File size should be less than 10 MB! ', //+ v.size + " " + (v.length * 10000000),
  domain: (value) => /^[a-zA-Z]+$/.test(value) || "Latin letters only",
  min: (v) => v.length >= 8 || "Min 8 characters",
};

Vue.prototype.$catch = function (response) {
  let alertMsg;
  switch (response.response.status) {
    case 500:
      alertMsg = this.$vuetify.lang.t("$vuetify.error500");
      break;
    case 400:
      alertMsg = response.response.data.errors;
      break;
    case 401:
      this.$refresh();
      break;
    case 403:
      alertMsg = "Нет доступа";
      this.$router.push({name: '404'})
      break;
    case 404:
      alertMsg = "Объект не найден";
      this.$router.push({name: '404'})
      break;
    case 405:
      alertMsg = "Метод не разрешен";
      break;
    default:
      alertMsg = "Непредвиденная ошибка";
  }
  return alertMsg;
};
Vue.prototype.$refresh = function () {
  localStorage.clear();
  sessionStorage.clear();
  this.$router.push({name: "AuthLayout"});
};
Vue.prototype.$check_permissions = function (part = null, admin = null) {
  if (localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')) {
    if (part != null && this.$parent.read.indexOf(part) === -1) {
      this.$router.push({name: "404"})
      return false
    } else if (admin != null) {
      console.log("admin")
      if (this.$parent.admin)
        return true
      else {
        this.$router.push({name: "404"})
        return false
      }
    } else {
      return true
    }
  } else {
    this.$router.push({name: 'AuthLayout'})
    return false
  }
}

Vue.prototype.$saveForm = function (field, data) {
  console.log(data)
  localStorage.setItem(field, JSON.stringify(data))
}
Vue.prototype.$loadForm = function (field) {
  if (localStorage.getItem(field)) {
    return JSON.parse(localStorage.getItem(field))
  } else {
    return {}
  }
}
Vue.prototype.$clearForm = function (field) {
  localStorage.removeItem(field)
}

Vue.prototype.$toggleTheme = function (color = null, language = null) {
  console.log("toggleTheme global " + color + " " + language);
  if (language != null) {
    switch (language) {
      case 'ru':
        localStorage.setItem("lang", 'rus')
        language = 'rus'
        break
      case 'en':
        localStorage.setItem("lang", 'eng')
        language = 'eng'
        break
    }
    this.$vuetify.lang.current = language;
    localStorage.setItem("lang", language)
  }
  switch (color) {
    case "dark":
      localStorage.setItem("theme", "dark")
      require("./assets/sass/colors/dark.sass");
      document.documentElement.setAttribute('data-theme', 'dark')
      break;
    case "light":
      localStorage.setItem("theme", "light")
      document.documentElement.setAttribute('data-theme', 'light')
      require("./assets/sass/colors/light.sass");
      break;
    case "green":
      localStorage.setItem("theme", "green")
      document.documentElement.setAttribute('data-theme', 'green')
      require("./assets/sass/colors/green.sass");
      break;
    // default:
    //   localStorage.setItem("theme", "light")
    //   require("./assets/sass/colors/light.sass");
    //   document.documentElement.setAttribute('data-theme', 'light')
    //   break;
  }
  if (localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token")) {
    this.$axios({
      url: this.$hostname + "time-tracking/user-settings",
      method: "PUT",
      headers: {
        Authorization:
          "Token " +
          (localStorage.getItem("auth_token") ||
            sessionStorage.getItem("auth_token")),
        "Content-Type": "multipart/form-data",
      },
      data: {
        language: language,
        theme: color
      }
    }).then(() => {
      this.lang_modal = false
      // this.$toggleTheme(this.settings.theme, this.settings.language)
    }).catch((response) => {
      this.alertMsg = this.$catch(response)
      this.alertError = true
    })
  }
};

export default {
  name: "App",

  components: {},

  created() {
    console.log(window.location.hostname)
    this.$toggleTheme(localStorage.getItem("theme") || 'light', localStorage.getItem("lang") || this.$vuetify.lang.current)
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    (function init100vh() {
      function setHeight() {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }

      setHeight();
      window.addEventListener('resize', setHeight);
    })();
    document.title = this.$vuetify.lang.t('$vuetify.menu.' + this.$route.name)
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="sass">
@import "assets/sass/style.sass"
</style>
