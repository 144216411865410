<template>
  <div class="page-wrap">
    <div class="page-head">
      <h1 class="h1 dTm-none">{{ $vuetify.lang.t('$vuetify.menu.news') }}</h1>
      <div class="page-head__buttons">
        <button class="btn-secondary btn" @click="arch = !arch">
          <span v-if="!arch">{{ $vuetify.lang.t('$vuetify.archive') }}</span>
          <span v-else>{{ $vuetify.lang.t('$vuetify.outArchive') }}</span>
        </button>
        <router-link class="btn btn-secondary link-not-like" to="/newsAdd" v-if="$parent.admin">+
          {{ $vuetify.lang.t('$vuetify.addNew') }}
        </router-link>
      </div>
    </div>
    <!--    <h2 class="h2">Недавние</h2>-->
    <div class="cards news-cards">
      <news-card
        v-for="newsCard in news"
        :key="newsCard.id"
        :image="$media_url + newsCard.photo_path"
        :title="newsCard.title"
        :id="newsCard.id"
        :author="newsCard.author ? (newsCard.author.name + ' ' + newsCard.author.lastname) : ''"
        :date="newsCard.create_date"
      ></news-card>
    </div>
  </div>
</template>

<script>
import NewsCard from "./parts/NewsCard.vue";

export default {
  name: "news-list",
  components: {
    NewsCard,
  },

  data: function () {
    return {
      news: [],
      arch: false,
    };
  },
  watch: {
    arch: function () {
      this.loadData()
    },
  },

  mounted: function () {
    this.loadData();
  },

  methods: {
    loadData() {
      this.$axios({
        url: this.$hostname + "time-tracking/news",
        method: "GET",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
        params: {
          arch: !this.arch
        }
      }).then((response) => {
        this.news = response.data.data.data;
      });
      if (localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token")) {
        this.$axios({
          url: this.$hostname + "time-tracking/news/unread",
          method: "DELETE",
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
          },
        })
      }
    },
  },
};
</script>
