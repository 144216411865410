<template>
  <div class="sign-up">
    <div v-if="step == 1" class="sign-up__card cards__single" key="step1">
      <div class="sign-up__header">
        <div class="sign-up__title h1">
          {{ $vuetify.lang.t('$vuetify.signup.wantRentTitle') }}
        </div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.wantRentSubtitle') }}
        </div>
      </div>
      <v-form ref="form" class="sign-up__form" @submit.prevent="">
        <v-text-field
          v-model="tenant.name"
          :label="$vuetify.lang.t('$vuetify.companyName')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="tenant.ogrn"
          :label="$vuetify.lang.t('$vuetify.regNumber')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="tenant.schema_name"
          :label="$vuetify.lang.t('$vuetify.subdomain')"
          suffix=".work24.fi"
          outlined
          :rules="[$rules.required, $rules.text]"
        ></v-text-field>
        <v-file-input
          v-model="logo"
          accept=".jpg, .png, .jpeg"
          :placeholder=" $vuetify.lang.t('$vuetify.logo')"
          prepend-icon="$photo"
          :rules="[$rules.fileSize]"
        ></v-file-input>
        <div class="sign-up__buttons btn-group">
          <button
            class="btn btn-secondary"
            @click="goLoginPage"
          >
            {{ $vuetify.lang.t('$vuetify.signup.cancel') }}
          </button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step == 2" class="sign-up__card cards__single" key="step2">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.contactInfoTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.contactInfoSubtitle') }}
        </div>
      </div>
      <v-form class="sign-up__form" ref="form" @submit.prevent="">
        <v-text-field
          v-model="tenant.email"
          label="E-mail"
          outlined
          :rules="[$rules.required, $rules.email]"
        ></v-text-field>
        <v-text-field
          v-model="tenant.phone"
          :label="$vuetify.lang.t('$vuetify.phone')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="tenant.web"
          :label="$vuetify.lang.t('$vuetify.website')"
          outlined
        ></v-text-field>
        <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step == 3" class="sign-up__card cards__single" key="step3">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.contactPerson') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.contactPersonSubtitle') }}
        </div>
      </div>
      <v-form class="sign-up__form" ref="form" @submit.prevent="">
        <v-text-field
          v-model="admin.name"
          :label="$vuetify.lang.t('$vuetify.signup.name')"
          outlined
          :rules="[$rules.required, $rules.text]"
        ></v-text-field>
        <v-text-field
          v-model="admin.lastname"
          :label="$vuetify.lang.t('$vuetify.signup.lastname')"
          outlined
          :rules="[$rules.required, $rules.text]"
        ></v-text-field>
        <v-text-field
          v-model="admin.email"
          label="E-mail"
          outlined
          :rules="[$rules.required, $rules.email]"
        ></v-text-field>
        <v-text-field
          v-model="admin.password"
          :label="$vuetify.lang.t('$vuetify.password')"
          outlined
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[$rules.required, $rules.min]"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
        ></v-text-field>
        <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="loadLogo">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <SpinnerLoader :overlay="loader"></SpinnerLoader>

    <div v-if="step == 4" class="sign-up__card cards__single" key="step11">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.congratTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.congratRentSubtitle') }}
        </div>
      </div>
      <button class="sign-up__final btn btn-primary" @click="goLoginPage">
        {{ $vuetify.lang.t('$vuetify.gotoProfile') }}
      </button>
    </div>
  </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader";

let base_hostname = 'http://127.0.0.1:8000/'

export default {
  name: "SignUpTenantLayout",
  components: {
    SpinnerLoader,
  },
  data: function () {
    return {
      tenant: {
        'schema_name': '',
        'name': '',
        'paid_until': new Date().toISOString().substr(0, 10),
        'created_on': new Date().toISOString().substr(0, 10),
        'on_trial': true,
        'address': {},
        'ogrn': '',
        'email': '',
        'phone': '',
        'web': '',
        'logo_path': '',
        'bank': '',
        'bank_account': '',
        'bic': ''
      },
      logo: null,
      admin: {},

      show: false,
      step: 1,
      loader: false,
      alertError: false,
      alertMsg: ''
    };
  },
  methods: {
    nextStep() {
      if (!this.$refs.form.validate()) {
        this.alertError = true;
        this.alertMsg = this.$vuetify.lang.t('$vuetify.fillForm');
      } else {
        this.step += 1;
      }
    },
    loadLogo() {
      if (this.$refs.form.validate() && this.logo) {
        this.loader = true
        this.$axios({
          url: base_hostname + "time-tracking/images/upload",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            file: this.logo,
          },
        })
          .then((response) => {
            this.tenant.logo_path = response.data.data;
          })
          .catch((error) => {
            this.alertMsg = "Ошибка загрузки файла";
            console.log(error);
            switch (error.response.status) {
              case 400:
                this.alertMsg = error.message;
                break;
              case 403:
                this.alertMsg = "Доступ запрещен";
                break;
              case 404:
                this.alertMsg = "Страница не найдена";
                break;
            }
            alert(this.alertMsg);
          }).finally(() => {
          this.addTenant()
        })
      } else {
        this.addTenant()
      }
    },
    addTenant() {
      if (this.$refs.form.validate()) {
        this.loader = true
        this.tenant.address = JSON.stringify(this.tenant.address)
        this.$axios({
          url: base_hostname + 'time-tracking/organization',
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            data: JSON.stringify(this.tenant),
            user: JSON.stringify({
              email: this.admin.email.toLowerCase().replace(" ", ""),
              password: this.admin.password,
            }),
          }
        })
          .then((response) => {
            this.tenant.schema_name = response.data.data.schema_name
            console.log(response.data.data.schema_name)
            // this.addUser()
            this.step += 1;
            this.loader = false
          })
          .catch((error) => {
            this.loader = false
            this.alertMsg = this.$catch(error)
            alert(this.alertMsg + ' Contact with admin');
            this.loader = false
          });
      }
    },
    addUser() {
      if (this.$refs.auth.validate()) {
        this.$axios({
          url: this.domain + '.work.24' + "time-tracking/register-user",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            user: {
              email: this.admin.email.toLowerCase().replace(" ", ""),
              password: this.admin,
            },
            schema_name: this.tenant.schema_name
          },
        }).then((response) => {
          console.log(response)
          this.step += 1;
          this.loader = false
        }).catch((error) => {
          this.alertMsg = this.$catch(error)
          this.alertError = true;
        });
      }
    },
    goLoginPage() {
      this.$router.push({name: "AuthLayout"});
    },
  }
}
</script>

<style scoped>

</style>